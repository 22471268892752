define('timetracker/components/user-administration', ['exports', 'ember-uuid', 'timetracker/utils/string-util', 'timetracker/utils/analytics'], function (exports, _emberUuid, _stringUtil, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    loginService: Ember.inject.service(),
    metrics: Ember.inject.service(),

    userSearchText: '',
    selectedUser: null,
    isNewUser: false,
    userName: '',
    userEmail: '',
    newPassword: '',
    userCanManageProjects: false,
    userCanManageUsers: false,
    userCanChangeBillingDetails: false,
    passwordChangeRequired: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('userList', this.get('users').sortBy('name'));
    },


    actions: {
      searchUsers: function searchUsers() {
        this.set('userList', _stringUtil.default.searchList(this.get('users'), this.get('userSearchText')));
      },
      addUser: function addUser() {
        this.clearErrorMessages();
        this.setProperties({
          selectedUser: {},
          userName: '',
          userEmail: '',
          userIsDisabled: false,
          userCanManageProjects: false,
          userCanManageUsers: false,
          userCanChangeBillingDetails: false,
          passwordChangeRequired: false,
          isEditMode: true,
          isNewUser: true,
          newPassword: ''
        });
      },
      userSelected: function userSelected(user) {
        this.clearErrorMessages();
        this.initializeSelectedUser(user);
      },
      enableEditing: function enableEditing() {
        this.clearErrorMessages();
        this.set('isEditMode', true);
      },
      cancelEditing: function cancelEditing() {
        this.clearErrorMessages();
        if (this.get('isNewUser')) {
          return this.setProperties({
            selectedUser: null,
            isNewUser: false
          });
        }
        this.initializeSelectedUser(this.get('selectedUser'));
      },
      disableEnableClicked: function disableEnableClicked() {
        var selectedUser = this.get('selectedUser');
        selectedUser.set('disabled', !selectedUser.get('disabled'));
        selectedUser.save().catch(function () {
          selectedUser.rollbackAttributes();
        });
      },
      saveUser: function saveUser() {
        var isNewUser = this.get('isNewUser');

        var _getProperties = this.getProperties('userName', 'userEmail', 'selectedUser'),
            userName = _getProperties.userName,
            userEmail = _getProperties.userEmail,
            selectedUser = _getProperties.selectedUser;

        var condensedEmailAddress = userEmail ? _stringUtil.default.condenseSpace(userEmail) : '';
        var condensedUserName = userName ? _stringUtil.default.condenseSpace(userName) : '';
        this.clearErrorMessages();
        if (!userName || condensedUserName.length <= 0) {
          this.set('userName', null);
          return Ember.$('.user-name-required').removeClass('hidden');
        }
        if (!userEmail || condensedEmailAddress.length <= 0) {
          this.set('userEmail', null);
          return Ember.$('.email-required').removeClass('hidden');
        }
        if (condensedEmailAddress.indexOf('@') === -1) {
          // eslint-disable-line no-magic-numbers
          return Ember.$('.invalid-email').removeClass('hidden');
        }
        var doesEmailAlreadyExist = this.get('users').any(function (item) {
          if (isNewUser) {
            return item.get('emailId') === condensedEmailAddress;
          }
          return item.get('emailId') === condensedEmailAddress && selectedUser.get('userId') !== item.id;
        });
        if (doesEmailAlreadyExist) {
          return Ember.$('.email-already-exist').removeClass('hidden');
        }
        if (isNewUser && !this.get('newPassword')) {
          return Ember.$('.password-required').removeClass('hidden');
        }
        this.setProperties({
          userName: _stringUtil.default.condenseSpace(userName),
          userEmail: condensedEmailAddress
        });
        this.executeSaveUser();
      },
      generatePassword: function generatePassword() {
        var newPassword = _stringUtil.default.generatePasswordString();
        this.set('newPassword', newPassword);
      }
    },

    initializeSelectedUser: function initializeSelectedUser(user) {
      this.setProperties({
        selectedUser: user,
        userName: user.get('name'),
        userEmail: user.get('emailId'),
        userCanManageProjects: user.get('canManageProjects'),
        userCanManageUsers: user.get('canManageUsers'),
        userCanChangeBillingDetails: user.get('canChangeBillingDetails'),
        isEditMode: false,
        isNewUser: false,
        passwordChangeRequired: user.get('passwordChangeRequired'),
        selectedUserIsLoggedInUser: this.get('loginService').user.userId === user.get('userId')
      });
    },
    clearErrorMessages: function clearErrorMessages() {
      Ember.$('.user-name-required, .email-already-exist, .email-required, .password-required, .invalid-email').addClass('hidden');
    },
    executeSaveUser: function executeSaveUser() {
      var store = this.get('store');
      var self = this;

      var _getProperties2 = this.getProperties('userName', 'userEmail', 'userCanManageProjects', 'userCanManageUsers', 'userCanChangeBillingDetails', 'selectedUser', 'passwordChangeRequired'),
          userName = _getProperties2.userName,
          userEmail = _getProperties2.userEmail,
          userCanManageProjects = _getProperties2.userCanManageProjects,
          userCanManageUsers = _getProperties2.userCanManageUsers,
          userCanChangeBillingDetails = _getProperties2.userCanChangeBillingDetails,
          selectedUser = _getProperties2.selectedUser,
          passwordChangeRequired = _getProperties2.passwordChangeRequired;

      var attributes = {
        userId: '',
        name: userName,
        emailId: userEmail,
        disabled: false, // can not edit a disabled user
        passwordChangeRequired: passwordChangeRequired,
        canManageProjects: userCanManageProjects,
        canManageUsers: userCanManageUsers,
        canChangeBillingDetails: userCanChangeBillingDetails
      };
      if (selectedUser.id) {
        attributes.userId = selectedUser.id;
        selectedUser.setProperties(attributes);
        selectedUser.save().catch(function (reason) {
          self.sendAction('handleError', reason);
          selectedUser.rollbackAttributes();
          self.initializeSelectedUser(selectedUser);
        });
        this.initializeSelectedUser(selectedUser);
        _analytics.default.sendUserModifiedEvent(this.get('metrics'));
      } else {
        attributes.userId = (0, _emberUuid.v4)();
        attributes.password = this.get('newPassword');
        var record = store.createRecord('admin-user', attributes);
        record.save().catch(function (reason) {
          self.sendAction('handleError', reason);
          record.rollbackAttributes();
          self.set('selectedUser', null);
          self.resetUsersList();
        });
        this.initializeSelectedUser(record);
        _analytics.default.sendUserCreatedEvent(this.get('metrics'));
      }
      this.resetUsersList();
    },
    resetUsersList: function resetUsersList() {
      var users = this.get('store').peekAll('admin-user');
      this.setProperties({
        userList: users.sortBy('name'),
        users: users,
        userSearchText: ''
      });
    }
  });
});