define('timetracker/adapters/user', ['exports', 'timetracker/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findAll: function findAll() {
      var url = this.buildURL('getUsers');
      return this.sendAjax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var url = this.buildURL('createUser');
      var record = snapshot.record;
      var data = {
        userId: record.get('userId'),
        password: record.get('password'),
        name: record.get('name'),
        emailId: record.get('emailId'),
        disabled: record.get('disabled'),
        passwordChangeRequired: record.get('passwordChangeRequired'),
        canManageProjects: record.get('canManageProjects'),
        canManageUsers: record.get('canManageUsers'),
        canChangeBillingDetails: record.get('canChangeBillingDetails')
      };
      return this.sendAjax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var url = this.buildURL('updateUser');
      var record = snapshot.record;
      var data = {
        userId: record.get('userId'),
        name: record.get('name'),
        emailId: record.get('emailId'),
        disabled: record.get('disabled'),
        passwordChangeRequired: record.get('passwordChangeRequired'),
        canManageProjects: record.get('canManageProjects'),
        canManageUsers: record.get('canManageUsers'),
        canChangeBillingDetails: record.get('canChangeBillingDetails')
      };
      return this.sendAjax(url, 'POST', { data: data });
    }
  });
});