define('timetracker/routes/authenticated/manage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    loginService: Ember.inject.service('loginService'),

    renderTemplate: function renderTemplate() {
      this.render('authenticated.manage.manageSidebar', {
        into: 'authenticated',
        outlet: 'sidebar'
      });
      this.render();
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('isAdmin', this.get('loginService').isAdmin);
    }
  });
});