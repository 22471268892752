define('timetracker/routes/application', ['exports', 'timetracker/constants', 'ember-uuid', 'timetracker/utils/application-setup'], function (exports, _constants, _emberUuid, _applicationSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    loginService: Ember.inject.service('loginService'),

    beforeModel: function beforeModel(transition) {
      // _apiRequestsPending will keep track of pending requests throughout app
      // if we need to verify window close event, this variable will be checked
      window._apiRequestsPending = 0;
      window._clientUuid = (0, _emberUuid.v4)().toString();

      (0, _applicationSetup.default)();

      var loginService = this.get('loginService');
      var transitionTarget = transition.targetName;
      // skip redirection to login route or any of index route in nested routes
      if (transitionTarget !== 'login' && transitionTarget !== 'not-found' && !transitionTarget.match(/index$/)) {
        loginService.redirectionUrl = transitionTarget;
      }
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      var self = this;
      Ember.run.next(this, '_layout');
      Ember.$(window).resize(function () {
        self._layout();
      });
    },

    _layout: function _layout() {
      var minHeight = _constants.default.SETUP.MINIMUM_SUPPORTED_HEIGHT;
      var minWidth = _constants.default.SETUP.MINIMUM_SUPPORTED_WIDTH;
      var windowHeight = Ember.$(window).innerHeight();
      var windowWidth = Ember.$(window).innerWidth();
      if (windowHeight > minHeight) {
        Ember.$('body').css({ 'height': windowHeight, 'overflow-y': 'hidden' });
      } else {
        Ember.$('body').css({ 'height': minHeight, 'overflow-y': 'scroll' });
      }
      if (windowWidth > minWidth) {
        Ember.$('body').css({ 'width': windowWidth, 'overflow-x': 'hidden' });
      } else {
        Ember.$('body').css({ 'width': minWidth, 'overflow-x': 'scroll' });
      }
    }
  });
});