define('timetracker/routes/authenticated/manage/profile', ['exports', 'timetracker/utils/analytics', 'timetracker/utils/network-error-handler'], function (exports, _analytics, _networkErrorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    loginService: Ember.inject.service('loginService'),
    metrics: Ember.inject.service(),

    user: null,

    model: function model(params, transition) {
      var loginService = this.get('loginService');
      return loginService.validate().then(function () {
        return loginService.user;
      }).catch(function (reason) {
        _networkErrorHandler.default.broadcastCustomEvent(reason);
        return transition.abort();
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('user', model);
    },


    actions: {
      didTransition: function didTransition() {
        _analytics.default.sendRouteEnteredEvent(this, this.get('metrics'));
      }
    }
  });
});