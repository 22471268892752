define('timetracker/utils/timeline-util', ['exports', 'timetracker/constants', 'moment'], function (exports, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var timelineUtil = {
    makeEvents: function makeEvents(timers, projects) {
      var project = void 0;
      var startTime = void 0;
      var endTime = void 0;
      var isRunningTimer = void 0;
      var events = [];
      for (var i = 0, l = timers.length; i < l; i++) {
        var currentTimer = timers[i];
        startTime = (0, _moment.default)(currentTimer.get('startTime'));
        isRunningTimer = currentTimer.get('endTime') === null || typeof currentTimer.get('endTime') === 'undefined';
        endTime = isRunningTimer ? (0, _moment.default)() : (0, _moment.default)(currentTimer.get('endTime'));
        startTime = (0, _moment.default)(currentTimer.get('startTime'));
        project = projects.findBy('id', currentTimer.get('projectId'));
        if (!project) {
          continue; // timer from archived project
        }
        // Fullcalendar will make end time as null if start and end happens to be the same
        // to fix this, we filter out all timers which runs less than a second.
        if (endTime.diff(startTime, 'seconds') < 1) {
          continue;
        }
        var event = {
          start: startTime.format(),
          end: endTime.format(),
          isRunning: isRunningTimer,
          backgroundColor: _constants.default.PROJECT_COLORS_TRANSLUCENT[project.get('colorCode')],
          borderColor: _constants.default.PROJECT_COLORS[project.get('colorCode')],
          id: currentTimer.get('id'),
          title: project.get('name'),
          projectId: project.get('id')
        };
        events.push(event);
      }
      return events;
    },
    getTotalMinutesForTheDay: function getTotalMinutesForTheDay(day, timers) {
      var minutes = 0;
      var timerEndTime = void 0;
      var timerStartTime = void 0;
      var timer = void 0;
      var timerStartTimeForTheDay = void 0;
      var timerEndTimeForTheDay = void 0;
      var dayStart = day.clone().startOf('day');
      var dayEnd = day.clone().add(1, 'day').startOf('day');
      for (var i = 0, l = timers.length; i < l; i++) {
        timer = timers[i];
        timerEndTime = timer.end;
        timerStartTime = timer.start;
        if (dayStart.isSameOrAfter(timerStartTime, 'day') && dayStart.isSameOrBefore(timerEndTime, 'day')) {
          if (dayStart.isAfter(timerStartTime)) {
            timerStartTimeForTheDay = dayStart;
          } else {
            timerStartTimeForTheDay = timerStartTime;
          }
          if (timerEndTime.isBefore(dayEnd)) {
            timerEndTimeForTheDay = timerEndTime;
          } else {
            timerEndTimeForTheDay = dayEnd;
          }
          minutes += timerEndTimeForTheDay.diff(timerStartTimeForTheDay, 'minutes');
        }
      }
      return minutes;
    },
    getHourMinuteStringFromMinutes: function getHourMinuteStringFromMinutes(inputMinutes, i18n) {
      var minuteDivisor = 60;
      var hours = Math.floor(inputMinutes / minuteDivisor);
      var minutes = inputMinutes % minuteDivisor;
      var formatString = void 0;
      var hourString = i18n.t('strings.time.hour', { count: hours });
      var minuteString = i18n.t('strings.time.minute', { count: minutes });
      if (hourString.string.length > 0 && minuteString.string.length > 0) {
        formatString = hourString + ' ' + minuteString;
      } else {
        formatString = '' + hourString + minuteString;
      }
      return formatString;
    },
    getWeekViewFormattedTime: function getWeekViewFormattedTime(inputMinutes) {
      var minuteDivisor = 60;
      var hours = Math.floor(inputMinutes / minuteDivisor);
      var minutes = inputMinutes % minuteDivisor;
      minutes = minutes < 10 ? '0' + minutes : '' + minutes; // eslint-disable-line no-magic-numbers
      return hours + ':' + minutes;
    },
    renderTotalTimeInFcHeader: function renderTotalTimeInFcHeader(formattedTime, target) {
      var span = document.createElement('span');
      span.appendChild(document.createTextNode(formattedTime));
      var targetRef = Ember.$(target);
      if (targetRef.children().length > 1) {
        Ember.$(targetRef.children(span)[1]).remove();
      }
      targetRef.append(span);
    },
    getTargetElementInWeekViewForTotalTime: function getTargetElementInWeekViewForTotalTime(dayOfWeek) {
      var weekDay = void 0;
      switch (dayOfWeek) {
        case 0:
          weekDay = '.fc-sun';
          break;
        case 1:
          weekDay = '.fc-mon';
          break;
        case 2:
          weekDay = '.fc-tue'; // eslint-disable-line no-magic-numbers
          break;
        case 3:
          weekDay = '.fc-wed'; // eslint-disable-line no-magic-numbers
          break;
        case 4:
          weekDay = '.fc-thu'; // eslint-disable-line no-magic-numbers
          break;
        case 5:
          weekDay = '.fc-fri'; // eslint-disable-line no-magic-numbers
          break;
        case 6:
          weekDay = '.fc-sat'; // eslint-disable-line no-magic-numbers
      }
      return 'th' + weekDay; // e.g. selector is of type th.fc-sun
    },
    getOverlappingTimers: function getOverlappingTimers(timerToTestAgainst) {
      var timers = Ember.$('.full-calendar').fullCalendar('clientEvents');
      var overlappingTimers = [];
      var start = timerToTestAgainst.start;
      var end = timerToTestAgainst.end.isValid() ? timerToTestAgainst.end : (0, _moment.default)();
      for (var i = 0, l = timers.length; i < l; i++) {
        if (timerToTestAgainst.id === timers[i].id) {
          continue;
        }
        if (start.isSameOrAfter(timers[i].start) && start.isBefore(timers[i].end) || start.isBefore(timers[i].start) && end.isAfter(timers[i].start) || timers[i].isRunning && !timerToTestAgainst.end.isValid()) {
          overlappingTimers.push(timers[i]);
        }
      }
      return overlappingTimers;
    },
    createFcEvent: function createFcEvent(timer, color, title) {
      return {
        id: timer.timerId,
        start: timer.startTime,
        end: timer.endTime,
        isRunning: timer.endTime === null,
        backgroundColor: _constants.default.PROJECT_COLORS_TRANSLUCENT[color],
        borderColor: _constants.default.PROJECT_COLORS[color],
        title: title,
        projectId: timer.projectId
      };
    }
  };

  exports.default = timelineUtil;
});