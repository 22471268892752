define('timetracker/utils/application-setup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = applicationSetup;

  var addCustomEventPolyfill = function addCustomEventPolyfill() {
    // reference https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
    (function () {

      if (typeof window.CustomEvent === 'function') {
        return false;
      }

      function CustomEvent(event, params) {
        // eslint-disable-line
        params = params || { bubbles: false, cancelable: false, detail: undefined }; // eslint-disable-line
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      CustomEvent.prototype = window.Event.prototype;

      window.CustomEvent = CustomEvent;
    })();
  };

  function applicationSetup() {
    addCustomEventPolyfill();
  }
});