define('timetracker/utils/recent-timers-aggregate', ['exports', 'moment', 'timetracker/utils/logger'], function (exports, _moment, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = recentTimersAggregate;


  var getTimerSecondsForToday = function getTimerSecondsForToday(timer, currentTime, zoneOffsetMinutes) {
    var seconds = 0;
    var startTime = (0, _moment.default)(timer.get('startTime')).utcOffset(zoneOffsetMinutes);
    var endTime = timer.get('endTime') ? (0, _moment.default)(timer.get('endTime')) : (0, _moment.default)(currentTime);
    endTime.utcOffset(zoneOffsetMinutes);
    var startOfToday = (0, _moment.default)(currentTime).utcOffset(zoneOffsetMinutes).startOf('day');

    // log errors for start time or end time after current time and also start time after end time
    if (startTime.isAfter(currentTime) || endTime.isAfter(currentTime) || startTime.isAfter(endTime)) {
      // log error
      if (startTime.isAfter(currentTime)) {
        _logger.default.info('start time: ' + startTime.format() + ' cannot be after current time: ' + currentTime);
      }
      if (endTime.isAfter(currentTime)) {
        _logger.default.info('end time: ' + endTime.format() + ' cannot be after current time: ' + currentTime);
      }
      if (startTime.isAfter(endTime)) {
        _logger.default.info('start time: ' + startTime.format() + ' cannot be after end time: ' + endTime.format());
      }
      // if the timer was stopped before startOfToday, we don't want it
    } else if (endTime.isBefore(startOfToday)) {
      // do nothing seconds will be 0, as timer ended before today
    } else {
      seconds = startTime.isAfter(startOfToday) ? endTime.diff(startTime, 'seconds') : endTime.diff(startOfToday, 'seconds');
    }
    return seconds;
  };

  /**
   * Returns an object that has formatted string for hours, minutes and seconds
   *
   *
   * e.g. totalSeconds = 500
   * will return {'0', '08', '20'}
   *
   *
   * @param {number} totalSeconds - seconds to be formatted to time
   * @return {{hours: string, minutes: string, seconds: string}} - an object of hours, minutes and seconds strings
   */
  var formatTime = function formatTime(totalSeconds) {
    var hourSeconds = 3600;
    var minuteSeconds = 60;
    var hours = '' + Math.floor(totalSeconds / hourSeconds);
    var minutes = Math.floor(totalSeconds / minuteSeconds % minuteSeconds);
    // add a preceding 0 if minutes < 10
    minutes = minutes < 10 ? '0' + minutes : '' + minutes; // eslint-disable-line no-magic-numbers
    var seconds = '' + totalSeconds % minuteSeconds;
    return { hours: hours, minutes: minutes, seconds: seconds };
  };

  var getSecondsRoundedDownToMinute = function getSecondsRoundedDownToMinute(secondsToRound) {
    return Math.floor(secondsToRound / 60) * 60; // eslint-disable-line
  };

  /**
   Return an array with date and no of hours worked on for the date.
   @param {array} timers - an array of timers, preferably within last 15 days
   @param {array} projects - an array of projects, preferably projects having timer within last 15 days
   @param {string} currentTime - an ISO date string
   @return {array} - an array of recent timers
   */
  function recentTimersAggregate(timers, projects) {
    var currentTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (0, _moment.default)();

    var zoneOffsetMinutes = _moment.default.parseZone(currentTime).utcOffset();
    var recentTimersCollection = {};
    var activeProjects = projects.filterBy('archived', false);
    timers.forEach(function (currentTimer) {
      var projectId = currentTimer.get('projectId');
      var timerProject = activeProjects.findBy('id', projectId);
      if (timerProject) {
        // if the project timer belongs to is not archived
        if (!recentTimersCollection[projectId]) {
          recentTimersCollection[projectId] = {
            projectId: projectId,
            name: timerProject.get('name'),
            colorCode: timerProject.get('colorCode'),
            seconds: 0,
            timerRunning: false,
            timerId: null,
            runningTimerHours: null,
            runningTimerMinutes: null
          };
        }
        var timerSecondsToday = getTimerSecondsForToday(currentTimer, currentTime, zoneOffsetMinutes);
        recentTimersCollection[projectId].seconds += getSecondsRoundedDownToMinute(timerSecondsToday);
        if (!currentTimer.get('endTime')) {
          var _formatTime = formatTime(timerSecondsToday),
              hours = _formatTime.hours,
              minutes = _formatTime.minutes,
              seconds = _formatTime.seconds;

          recentTimersCollection[projectId].timerRunning = true;
          recentTimersCollection[projectId].timerId = currentTimer.get('id');
          recentTimersCollection[projectId].runningTimerHours = hours;
          recentTimersCollection[projectId].runningTimerMinutes = minutes;
          recentTimersCollection[projectId].runningTimerSeconds = seconds;
        }
      }
    });

    for (var i in recentTimersCollection) {
      var _formatTime2 = formatTime(recentTimersCollection[i].seconds),
          hours = _formatTime2.hours,
          minutes = _formatTime2.minutes;

      recentTimersCollection[i].hours = hours;
      recentTimersCollection[i].minutes = minutes;
      delete recentTimersCollection[i].seconds;
    }
    // convert object to array
    return Object.keys(recentTimersCollection).map(function (key) {
      return recentTimersCollection[key];
    });
    // return recentTimersCollection.toArray();
  }
});