define('timetracker/components/report-table', ['exports', 'timetracker/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    reportsData: null,
    i18n: Ember.inject.service(),

    actions: {
      tableLoadComplete: function tableLoadComplete() {
        this.hideSpinner();
      }
    },

    hideSpinner: function hideSpinner() {
      Ember.$('.table-loading-state').css('display', 'none');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var reportsData = this.get('reportsData');
      var parameters = this.get('reportParameters');
      if (!reportsData || !parameters) {
        return;
      }
      if (parameters && reportsData.length === 0) {
        // empty response
        this.hideSpinner();
        return Ember.$('.message-body.empty-response').css('display', 'block');
      }
      this.setTableHeader(parameters);
    },
    setTableHeader: function setTableHeader(parameters) {
      var i18n = this.get('i18n');
      var levels = parameters.levels;
      var dateType = _constants.default.REPORT_LEVEL_OPTIONS.findBy('key', 'date').value;
      var projectType = _constants.default.REPORT_LEVEL_OPTIONS.findBy('key', 'project').value;
      var userType = _constants.default.REPORT_LEVEL_OPTIONS.findBy('key', 'user').value;
      var granularity = parameters.duration.granularity;
      var dayGranularity = _constants.default.GRANULARITY.findBy('key', 'day').value;
      var weekGranularity = _constants.default.GRANULARITY.findBy('key', 'week').value;
      var monthGranularity = _constants.default.GRANULARITY.findBy('key', 'month').value;
      var tableHeader = [];
      levels.forEach(function (levelItem) {
        if (levelItem === dateType) {
          switch (granularity) {
            case dayGranularity:
              tableHeader.push(i18n.t('strings.reports.headers.date').string);
              break;
            case weekGranularity:
              tableHeader.push(i18n.t('strings.reports.headers.week').string);
              break;
            case monthGranularity:
              tableHeader.push(i18n.t('strings.reports.headers.month').string);
          }
        } else if (levelItem === projectType) {
          tableHeader.push(i18n.t('strings.reports.headers.project').string);
        } else if (levelItem === userType) {
          tableHeader.push(i18n.t('strings.reports.headers.user').string);
        }
      });
      tableHeader.push(i18n.t('strings.reports.headers.duration').string);
      this.set('tableHeader', tableHeader);
    }
  });
});