define('timetracker/utils/sync-modal-handler-util', ['exports', 'moment', 'timetracker/utils/logger', 'timetracker/utils/timer-util'], function (exports, _moment, _logger, _timerUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var syncModalHandlerUtil = {
    resolveConflictingRecord: function resolveConflictingRecord(store, localData, serverData, keepServerDataFunction, keepLocalDataFunction, i18n) {
      var modalRef = Ember.$('.conflict-resolution-modal.ui.modal');
      var localDataRef = modalRef.find('.local-data');
      var serverDataRef = modalRef.find('.server-data');

      var jsonFormattedStoreTimer = _timerUtil.default.getJsonFormattedTimer(localData);
      if (jsonFormattedStoreTimer.changeToken === serverData.changeToken) {
        // discard if server CT equals local
        return keepLocalDataFunction();
      }
      if (this.isLocalAndServerDataSame(jsonFormattedStoreTimer, serverData)) {
        // discard local change if same as server
        return keepServerDataFunction();
      }

      this.fillTimerDetail(store, serverDataRef, serverData, i18n);
      this.fillTimerDetail(store, localDataRef, jsonFormattedStoreTimer, i18n);

      var closeModal = function closeModal() {
        modalRef.find('.ui.button.keep-server').unbind('click');
        modalRef.find('.ui.button.keep-local').unbind('click');
        modalRef.modal('hide');
      };

      return new Ember.RSVP.Promise(function (resolve) {
        modalRef.find('.ui.button.keep-server').on('click', function () {
          closeModal();
          keepServerDataFunction();
          return resolve();
        });

        modalRef.find('.ui.button.keep-local').on('click', function () {
          closeModal();
          keepLocalDataFunction();
          return resolve();
        });

        modalRef.modal({
          closable: false
        }).modal('show');
      });
    },
    fillTimerDetail: function fillTimerDetail(store, divRef, dataObject, i18n) {
      var dateFormat = 'llll';
      var strings = {
        currentlyRunning: i18n.t('strings.modal.currentlyRunning').toString(),
        yes: i18n.t('strings.modal.yes').toString(),
        no: i18n.t('strings.modal.no').toString()
      };
      var startTime = (0, _moment.default)(dataObject.startTime).format(dateFormat);
      var endTime = dataObject.endTime ? (0, _moment.default)(dataObject.endTime).format(dateFormat) : strings.currentlyRunning;
      var archivedStatus = dataObject.archived ? strings.yes : strings.no;
      var projectName = store.peekRecord('project', dataObject.projectId).get('name');
      divRef.find('.project-name .content').html(projectName);
      divRef.find('.starts-at .content').html(startTime);
      divRef.find('.ends-at .content').html(endTime);
      divRef.find('.is-archived .content').html(archivedStatus);
    },
    isLocalAndServerDataSame: function isLocalAndServerDataSame(localData, serverData) {
      if (localData.projectId !== serverData.projectId) {
        _logger.default.error('Conflicting project id found for timer with id ' + serverData.timerId);
      }
      return localData.archived === serverData.archived && localData.startTime === serverData.startTime && localData.endTime === serverData.endTime;
    }
  };

  exports.default = syncModalHandlerUtil;
});