define('timetracker/adapters/project', ['exports', 'timetracker/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findAll: function findAll(store, type, changeToken) {
      var queryParams = void 0;
      if (changeToken) {
        queryParams = {
          changeToken: changeToken
        };
      }
      var url = this.buildURL('getProjects', queryParams);
      return this.sendAjax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var url = this.buildURL('createProject');
      var record = snapshot.record;
      var data = {
        projectId: record.get('projectId'),
        name: record.get('name'),
        colorCode: record.get('colorCode'),
        archived: record.get('archived'),
        userIds: record.get('userIds')
      };
      return this.sendAjax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var url = this.buildURL('updateProject');
      var record = snapshot.record;
      var data = {
        projectId: record.get('projectId'),
        name: record.get('name'),
        colorCode: record.get('colorCode'),
        archived: record.get('archived'),
        changeToken: record.get('changeToken'),
        userIds: record.get('userIds')
      };
      return this.sendAjax(url, 'POST', { data: data });
    }
  });
});