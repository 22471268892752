define('timetracker/utils/network-request', ['exports', 'timetracker/config/environment', 'timetracker/constants', 'ember-uuid'], function (exports, _environment, _constants, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var networkRequest = {
    makeAPIRequest: function makeAPIRequest(ajaxObject) {
      var maxRetries = _constants.default.REQUEST.MAX_RETRIES;
      var timeoutCount = 0;
      var serviceUnavailableCount = 0;
      window._apiRequestsPending += 1;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajaxObject.success = function (payload) {
          window._apiRequestsPending -= 1;
          return resolve(payload);
        };
        ajaxObject.error = function (reason) {
          var statusCode502 = _constants.default.RESPONSE_CODES.SERVICE_UNAVAILABLE_502;
          var statusCode503 = _constants.default.RESPONSE_CODES.SERVICE_UNAVAILABLE_503;
          var statusTimeout = _constants.default.RESPONSE_CODES.NETWORK_ERROR;
          var retryAfterMS = _constants.default.REQUEST.RETRY_MS;
          var status = reason.status;
          if (status === statusCode502 || status === statusCode503) {
            serviceUnavailableCount += 1;
            var response = reason.responseText.length > 0 ? JSON.parse(reason.responseText) : {};
            if (response.retryAfterMS) {
              retryAfterMS = response.retryAfterMS;
            }
          } else if (status === statusTimeout) {
            timeoutCount += 1;
          } else {
            window._apiRequestsPending -= 1;
            return reject(reason);
          }
          if (timeoutCount > maxRetries || serviceUnavailableCount > maxRetries) {
            window._apiRequestsPending -= 1;
            return reject(reason);
          }
          Ember.run.later(this, function () {
            Ember.$.ajax(ajaxObject);
          }, retryAfterMS);
        };
        Ember.$.ajax(ajaxObject);
      });
    },
    buildURL: function buildURL(path) {
      var queryObject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var url = void 0;
      var host = _environment.default.APP.host;
      if (!host.endsWith('/')) {
        host = host + '/';
      }
      var apiVersion = _environment.default.APP.apiVersion;
      if (!apiVersion.endsWith('/')) {
        apiVersion = apiVersion + '/';
      }
      url = '' + host + apiVersion + path;
      queryObject[_constants.default.REQUEST_ID] = (0, _emberUuid.v4)().toString();
      queryObject[_constants.default.CLIENT_ID] = window._clientUuid;
      var queryString = this.formQueryString(queryObject);
      url = url + '?' + queryString;
      return url;
    },
    formQueryString: function formQueryString(queryObject) {
      var queryString = '';
      var encodedURI = void 0;
      var firstIndex = 0;
      Object.keys(queryObject).forEach(function (key) {
        encodedURI = encodeURIComponent(queryObject[key]);
        queryString += key + '=' + encodedURI + '&';
      });
      var queryLength = queryString.length;
      queryString = queryString.substring(firstIndex, queryLength - 1);
      return queryString;
    },
    setRequestParameters: function setRequestParameters(url, requestMethod) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var hash = options;
      hash.url = url;
      hash.type = requestMethod;
      hash.dataType = 'json';
      hash.timeout = _constants.default.REQUEST.TIMEOUT_MS;
      hash.xhrFields = {
        withCredentials: true
      };
      hash.context = this;
      if (hash.data) {
        hash.data = JSON.stringify(hash.data);
      }
      return hash;
    }
  };
  exports.default = networkRequest;
});