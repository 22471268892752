define('timetracker/components/time-picker', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var hourInputTimer = void 0;
  var minuteInputTimer = void 0;
  var waitSingleDigitInputMS = 2000; // we wait for 2 seconds on single digit input
  var am = void 0;
  var pm = void 0;

  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),

    tagName: 'span',

    time: null,
    formattedTime: null,

    timeChanged: Ember.observer('time', function () {
      var _getTwelveHourTime = this.getTwelveHourTime(this.get('time')),
          hour = _getTwelveHourTime.hour,
          minute = _getTwelveHourTime.minute,
          meridiem = _getTwelveHourTime.meridiem;

      this.set('formattedTime', hour + ':' + minute + '  ' + meridiem);
      this.setProperties({ uiHour: hour, uiMinute: minute, uiMeridiem: meridiem, selectedHour: parseInt(hour, 10), selectedMinute: parseInt(minute, 10), selectedMeridiem: meridiem });
    }),

    selectedMeridiem: null,
    selectedHour: null,
    selectedMinute: null,

    uiMeridiem: null,
    uiHour: null,
    uiMinute: null,

    minHour: 0,
    maxHour: 23,
    minMinute: 0,
    maxMinute: 59,

    init: function init() {
      this._super.apply(this, arguments);
      var i18n = this.get('i18n');
      am = i18n.t('strings.time.am');
      pm = i18n.t('strings.time.pm');
    },
    didRender: function didRender() {
      Ember.$('.time-picker-input').popup({
        inline: true,
        exclusive: true,
        hoverable: true,
        on: 'click',
        position: 'bottom left'
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('time') === null) {
        var currentTime = (0, _moment.default)();
        this.setProperties({
          selectedMeridiem: currentTime.format('a') === 'am' ? am : pm,
          selectedHour: parseInt(currentTime.format('hh'), 10),
          selectedMinute: parseInt(currentTime.format('mm'), 10)
        });
        this.validateSelectedTimeAndUpdateUI();
      }
    },


    actions: {
      toggleMeridiem: function toggleMeridiem() {
        var current = this.get('uiMeridiem');
        var meridiem = current === am ? pm : am;
        this.set('selectedMeridiem', meridiem);
        this.validateSelectedTimeAndUpdateUI();
        this.updateTime();
      },
      incrementHour: function incrementHour() {
        var hour = parseInt(this.get('uiHour'), 10);
        var maxHour = 12;
        hour = hour >= maxHour ? 1 : hour + 1;
        this.set('selectedHour', hour);
        this.validateSelectedTimeAndUpdateUI();
        this.updateTime();
      },
      decrementHour: function decrementHour() {
        var hour = parseInt(this.get('uiHour'), 10);
        var maxHour = 12;
        hour = hour <= 1 ? maxHour : hour - 1;
        this.set('selectedHour', hour);
        this.validateSelectedTimeAndUpdateUI();
        this.updateTime();
      },
      incrementMinute: function incrementMinute() {
        var minute = parseInt(this.get('uiMinute'), 10);
        var maxMinute = 59;
        minute = minute >= maxMinute ? 0 : minute + 1;
        this.set('selectedMinute', minute);
        this.validateSelectedTimeAndUpdateUI();
        this.updateTime();
      },
      decrementMinute: function decrementMinute() {
        var minute = parseInt(this.get('uiMinute'), 10);
        var maxMinute = 59;
        minute = minute <= 0 ? maxMinute : minute - 1;
        this.set('selectedMinute', minute);
        this.validateSelectedTimeAndUpdateUI();
        this.updateTime();
      },
      hourInput: function hourInput(input) {
        var expression = /^\d{1,2}$/;
        if (expression.test(input)) {
          var waitTime = 0;
          if (input.length === 1) {
            waitTime = waitSingleDigitInputMS;
          }
          var inputDigit = parseInt(input, 10);
          if (inputDigit < 1) {
            return;
          }
          var self = this;
          if (hourInputTimer) {
            Ember.run.cancel(hourInputTimer);
          }
          hourInputTimer = Ember.run.later(function () {
            self.set('selectedHour', inputDigit);
            self.validateSelectedTimeAndUpdateUI();
            self.updateTime();
          }, waitTime);
        }
      },
      minuteInput: function minuteInput(input) {
        var expression = /^\d{1,2}$/;
        if (expression.test(input)) {
          var waitTime = 0;
          if (input.length === 1) {
            waitTime = waitSingleDigitInputMS;
          }
          var self = this;
          if (minuteInputTimer) {
            Ember.run.cancel(minuteInputTimer);
          }
          minuteInputTimer = Ember.run.later(function () {
            self.set('selectedMinute', parseInt(input, 10));
            self.validateSelectedTimeAndUpdateUI();
            self.updateTime();
          }, waitTime);
        }
      }
    },

    getTwelveHourTime: function getTwelveHourTime(time) {
      if (time === null) {
        return { hour: null, minute: null, meridiem: null };
      }
      var minute = parseInt(time.split(':')[1], 10);
      var hour = parseInt(time.split(':')[0], 10);
      var noonHour = 12;
      var meridiem = hour >= noonHour ? pm : am;
      if (hour === 0) {
        hour = noonHour;
      } else if (hour > noonHour) {
        hour %= noonHour;
      }
      return { hour: this.appendZero(hour), minute: this.appendZero(minute), meridiem: meridiem };
    },
    appendZero: function appendZero(number) {
      return number < 10 ? '0' + number : '' + number; // eslint-disable-line
    },
    validateSelectedTimeAndUpdateUI: function validateSelectedTimeAndUpdateUI() {
      var _getProperties = this.getProperties('selectedHour', 'selectedMinute', 'selectedMeridiem'),
          selectedHour = _getProperties.selectedHour,
          selectedMinute = _getProperties.selectedMinute,
          selectedMeridiem = _getProperties.selectedMeridiem;

      var _getProperties2 = this.getProperties('maxHour', 'maxMinute', 'minHour', 'minMinute'),
          maxHour = _getProperties2.maxHour,
          maxMinute = _getProperties2.maxMinute,
          minHour = _getProperties2.minHour,
          minMinute = _getProperties2.minMinute;

      var hours = selectedHour;
      var minutes = selectedMinute;
      var midHour = 12;
      if (Number.isInteger(selectedHour)) {
        if (selectedMeridiem === pm) {
          hours = selectedHour === midHour ? selectedHour : selectedHour + midHour;
        } else if (selectedMeridiem === am) {
          hours = selectedHour === midHour ? 0 : selectedHour;
        }
        if (hours > maxHour) {
          hours = maxHour;
        } else if (hours < minHour) {
          hours = minHour;
        }
      } else {
        hours = null;
      }
      if (Number.isInteger(selectedMinute)) {
        if (minutes > maxMinute) {
          minutes = maxMinute;
        } else if (minutes < minMinute) {
          minutes = minMinute;
        }
      } else {
        minutes = null;
      }
      if (Number.isInteger(hours) && Number.isInteger(minutes)) {
        var time = hours + ':' + minutes;

        var _getTwelveHourTime2 = this.getTwelveHourTime(time),
            hour = _getTwelveHourTime2.hour,
            minute = _getTwelveHourTime2.minute,
            meridiem = _getTwelveHourTime2.meridiem;

        this.setProperties({ uiHour: hour, uiMinute: minute, uiMeridiem: meridiem });
      } else {
        this.setProperties({ uiHour: hours, uiMinute: minutes, uiMeridiem: selectedMeridiem });
      }
    },
    updateTime: function updateTime() {
      var _getProperties3 = this.getProperties('uiHour', 'uiMinute', 'uiMeridiem'),
          uiHour = _getProperties3.uiHour,
          uiMinute = _getProperties3.uiMinute,
          uiMeridiem = _getProperties3.uiMeridiem;

      var longHours = parseInt(uiHour, 10);
      var minutes = parseInt(uiMinute, 10);
      var noonHour = 12;
      if (uiMeridiem === pm) {
        longHours = longHours === noonHour ? longHours : longHours + noonHour;
      } else if (longHours === noonHour) {
        longHours = 0;
      }
      if (isNaN(minutes) || isNaN(longHours)) {
        return;
      }
      var time = longHours + ':' + uiMinute;
      this.sendAction('setTime', time);
    }
  });
});