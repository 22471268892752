define('timetracker/utils/logger', ['exports', 'timetracker/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /* eslint-disable no-console*/

  var logger = {
    info: function info(message) {
      console.log(message);
    },
    warn: function warn(message, data) {
      console.warn(message);
      Raven.captureMessage(message, { // eslint-disable-line no-undef
        level: 'warning',
        extra: data
      });
    },
    error: function error(message, data) {
      console.error(message);
      Raven.captureMessage(message, { // eslint-disable-line no-undef
        extra: data
      });
    },
    debug: function debug(message) {
      if (_environment.default.environment === 'development') {
        console.log(message);
      }
    }
  };

  exports.default = logger;
});