define('timetracker/utils/network-error-handler', ['exports', 'timetracker/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var networkErrorHandler = {
    handleErrorResponse: function handleErrorResponse(failedReason, url) {
      var requestId = this._parseRequestId(url);
      if (failedReason.status !== _constants.default.RESPONSE_CODES.BAD_REQUEST) {
        this.broadcastCustomEvent(failedReason);
      }
      return this._makeErrorResponse(failedReason, requestId);
    },
    broadcastCustomEvent: function broadcastCustomEvent(failedReason) {
      var adapterErrorEvent = new CustomEvent(_constants.default.EVENTS.NETWORK_REQUEST_ERROR);
      adapterErrorEvent.reason = failedReason;
      dispatchEvent(adapterErrorEvent);
    },
    _makeErrorResponse: function _makeErrorResponse(reason, requestId) {
      var error = {
        id: requestId,
        status: reason.status,
        code: reason.status,
        detail: reason.statusText,
        meta: {
          errorCode: reason.responseJSON ? reason.responseJSON.errorCode : ''
        }
      };
      return {
        errors: [error]
      };
    },
    _parseRequestId: function _parseRequestId(url) {
      // https://stackoverflow.com/a/901144/6293468
      var regex = new RegExp('[?&]' + _constants.default.REQUEST_ID + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(url);
      if (!results) {
        return null;
      }
      if (!results[2]) {
        // eslint-disable-line no-magic-numbers
        return '';
      }
      return decodeURIComponent(results[2].replace(/\+/g, ' ')); // eslint-disable-line no-magic-numbers
    },
    getErrorObject: function getErrorObject(adapterError) {
      return adapterError.errors.errors[0];
    },
    getErrorStatus: function getErrorStatus(adapterError) {
      return adapterError.errors.errors[0].status;
    },
    getFouHundredErrorCode: function getFouHundredErrorCode(adapterError) {
      var error = adapterError.errors.errors[0];
      return error.meta.errorCode;
    }
  };

  exports.default = networkErrorHandler;
});