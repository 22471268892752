define('timetracker/adapters/project-report-timer', ['exports', 'timetracker/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    /**
     This method is invoked by store for query method on store
     @method query
     @param {string} store
     Store class that invoked the method
     @param {string} type
     Type of request method
     @param {object} params
     Extra parameters for method, contains data to be send, query parameters.
     @return {Promise} a promise from ajax request defined in application adapter
     */
    query: function query(store, type, params) {
      var queryParams = {
        projectId: params.data.reportFor.get('id'),
        from: params.data.fromDate,
        to: params.data.toDate
      };
      var url = this.buildURL('projectReport', queryParams);
      return this.sendAjax(url, 'GET');
    }
  });
});