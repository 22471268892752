define('timetracker/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    messages: {
      unauthorizedLogin: 'Invalid credentials <br /> Please check your credentials and try again.',
      unauthorizedResponse: 'It appears your login credentials are no longer valid.<br /> Please log in again to continue.',
      userIsDisabled: 'Your account has been disabled.<br /> If you believe this has been done in error, please contact your organization’s administrators.',
      serviceUnavailable: 'Request could not be completed as we could not reach our servers.<br /> Please try again later.',
      authTokenExpired: 'It appears that your login session has expired.<br /> Please log in again to continue.',
      networkError: 'Request could not be completed as we could not reach our servers.<br /> Please check your network connection, and try again.',
      genericError: 'Something went wrong and we could not complete your request.<br /> Please try again later.',
      userDoesNotExistForgotPasssword: 'Invalid team name or email id.',
      tooManyAttemptsForgotPassword: 'Too many attempts to change password.',
      unexpectedError: 'Something unexpected happened. Please try again later, or contact support.',

      reportsDefaultMessage: 'Set parameters above',
      emptyReportsMessage: 'No records found',

      emptyRecentTimers: 'Start a timer from projects list',
      emptyProjectsNonAdmin: 'You have not been given access to any projects. Please contact your organization’s administrators.',
      emptyProjectsAdminMessage: 'Looks empty here',
      emptyProjectsAdminLink: 'Let us get you started',
      emptyProjectsSearch: 'No projects matched your search',

      overlappingTimersConfirmation: 'This will result in modifying other timers. <br /> Do you wish to continue?',
      archiveTimerConfirmation: 'Are you sure you want to archive the timer?',

      noTimersRunning: 'No timers running',
      clearEndTime: 'Clear end time',

      lostMessage404Page: 'You seem to be lost!',
      linkMessage404Page: 'Let us take you home.',

      passwordChangeSuccess: 'Password changed successfully',
      passwordChangeFailure: 'Incorrect old password',
      confirmPasswordMatch: 'Should match with the new password',
      nameChangeSuccess: 'Name changed successfully',
      nameChangeFailure: 'Name change failed',
      allFieldsRequired: 'Please fill in all the fields',
      confirmPasswordMatchAlt: 'Confirm new password should match with new password',
      requirePasswordChangeNote: 'For securities measure you are requested to change your account password.',

      selectProjectAdminPage: 'Select a project from the list',
      createProject: 'Create a new project using the "Add" button',
      emptyUserList: 'A project must have at least one user associated with it',
      emptyUserSearch: 'No user with that name was found',

      projectNameRequired: 'Project name is required',
      projectAlreadyExist: 'Project already exists',

      userNameRequired: 'User name is required',
      emailAlreadyExist: 'Email id already exists',
      emailRequired: 'Email id is required',
      emailIsInvalid: 'Please enter a valid email address',
      passwordRequired: 'Password is required',
      requestCouldNotBeCompleted: 'We could not complete your request',
      pleaseTryLater: 'Please try again later',
      atLeastOneUserShouldManageUsers: 'Please make sure that there is at least one user to manage users',
      atLeastOneUserShouldManageProjects: 'Please make sure that there is at least one user to manage projects',
      atLeastOneUserShouldBeAbleToChangeBilling: 'Please make sure that there is at least one user to update billing details',

      unauthorizedPageMessage: 'You do not have access to this. Contact your admin.',
      unauthorizedLinkMessage: 'Take me home',

      signedInAs: 'Signed in as',
      signUpText: 'If you do not have an account, sign up for one at',

      selectUserAdminPage: 'Select a user from the list',
      addUserAdminPage: 'Add users using the "Add" button',
      manageProjects: 'If the above field is checked, the user is granted permission to manage projects within the organization.',
      manageUsers: 'If the above field is checked, the user is granted permission to manage users within the organization.',
      changeBillingDetails: 'If the above field is checked, the user is granted permission to alter billing details for the organization.',
      passwordChangeRequiredCreate: 'If the above field is checked, the user is forced to change their password the first time they sign in.',
      passwordChangeRequiredUpdate: 'If the above field is checked, the user is forced to change their password when they next use Timing.',

      startAfterCurrent: 'A timer\u2019s start time can\u2019t be after the current time.',
      endAfterCurrent: 'A timer\u2019s end time can\u2019t be after the current time.',
      startAfterEnd: 'A timer\u2019s end time should be after its start time.',

      emailNotVerifiedMessage: 'Your email id <strong>{{emailId}}</strong> for the team <strong>{{teamName}}</strong> is not verified.<br />Please verify it to be able to log in.',
      forgotPassword: 'Enter your team name and email.<br/>You should receive an email with a link to reset your password shortly.'
    },

    strings: {
      loginScreen: {
        team: 'Team',
        email: 'Email',
        password: 'Password',
        signIn: 'Sign In'
      },

      appSidebar: {
        timers: 'Timers',
        timeline: 'Timeline',
        reports: 'Reports',
        profile: 'Profile',
        administration: 'Administration'
      },

      navBar: {
        signOut: 'Sign Out',
        myProfile: 'My Profile',
        administration: 'Administration'
      },

      timers: {
        timers: 'Timers',
        projects: 'Projects'
      },

      general: {
        search: 'Search…'
      },

      reports: {
        reports: 'Reports',
        projects: 'Projects',
        users: 'Users',
        fromDate: 'From Date:',
        toDate: 'To Date:',
        granularity: 'Granularity',
        showDays: 'Show Day Names',
        startOfWeek: 'Start of Week',
        grouping: 'Grouping',
        selectProjects: 'Select Projects',
        allUsers: 'All Users',
        selectUser: 'Select Users',
        allProjects: 'All Projects',
        datePlaceholder: 'Select Date',
        date: 'Date',
        day: 'Day',
        total: 'Total',
        timeFormat: 'Time Format',
        roundOffMinutes: 'Round off Min',
        roundOffType: 'Round Off',
        getReport: 'Get Report',
        getCSV: 'Get CSV',
        getExcel: 'Get Excel',
        parameters: 'Parameters',
        granularityOptions: {
          day: 'Day',
          week: 'Week',
          month: 'Month'
        },
        weekDays: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday'
        },
        levelOptions: {
          date: 'Date',
          project: 'Project',
          user: 'User'
        },
        roundingTypeOptions: {
          nearestInteger: 'Nearest Value',
          alwaysRoundUp: 'Round Up',
          alwaysRoundDown: 'Round Down'
        },
        timeFormats: {
          hourMinutes: 'HH:MM',
          minutes: 'Minutes',
          hours: 'Hours'
        },
        seeLevelOneOnly: 'See {{levelOneType}}s Only',
        seeLevelOneAndTwo: 'See {{levelOneType}}s and {{levelTwoType}}s Only',
        headers: {
          date: 'Date',
          week: 'Week',
          month: 'Month',
          user: 'User',
          project: 'Project',
          duration: 'Duration'
        }
      },

      paging: {
        previous: 'Previous',
        next: 'Next',
        first: 'First',
        last: 'Last',
        pages: '{{count}} Pages'
      },

      timeline: {
        goTo: ' Go to',
        week: 'Week',
        day: 'Day',
        month: 'Month',
        today: 'Today',
        buttons: {
          add: 'Add',
          update: 'Update',
          create: 'Create',
          archive: 'Archive',
          cancel: 'Cancel',
          continue: 'Continue',
          edit: 'Edit'
        },
        start: 'Start',
        end: 'End',
        duration: 'Duration'
      },

      time: {
        today: 'Today',
        hour: {
          zero: '',
          one: '{{count}} Hour',
          other: '{{count}} Hours'
        },
        minute: {
          zero: '',
          one: '{{count}} Minute',
          other: '{{count}} Minutes'
        },
        am: 'AM',
        pm: 'PM'
      },

      modal: {
        oops: 'Oops!',
        dismiss: 'Dismiss',
        projectName: 'Project Name',
        selectProject: 'Select Project',
        startTime: 'Start Time',
        endTime: 'End Time',
        timerFor: 'Timer For',
        startsAt: 'Starts At',
        endsAt: 'Ends At',
        conflictHeading: 'Conflict Detected!',
        archived: 'Archived',
        serverData: 'Server Data',
        localData: 'Local Data',
        acceptServer: 'Accept Server',
        acceptLocal: 'Accept Local',
        currentlyRunning: 'Currently Running',
        yes: 'Yes',
        no: 'No',
        resendEmail: 'Resend Email',
        forgotPassowLink: 'Forgot Password?',
        send: 'Send',
        cancel: 'Cancel'
      },

      profile: {
        profile: 'Profile',
        changePicture: 'Change Picture',
        email: 'Email',
        name: 'Name',
        updateName: 'Update Name',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm New Password',
        updatePassword: 'Update Password'
      },

      administration: {
        projects: 'Projects',
        yes: 'Yes',
        no: 'No',
        all: 'All',
        add: 'Add',
        edit: 'Edit',
        save: 'Save',
        cancel: 'Cancel',
        archive: 'Archive',
        unarchive: 'Unarchive',
        remove: 'Remove',
        name: 'Name',
        color: 'Color',
        users: 'Users',
        userName: 'User Name',
        projectName: 'Project Name',
        userEmail: 'User Email Id',
        email: 'Email',
        password: 'Password',
        disable: 'Disable',
        enable: 'Enable',
        generatePassword: 'Generate Password',
        canManageProjects: 'Can Manage Projects',
        canManageUsers: 'Can Manage Users',
        canChangeBillingDetails: 'Can Change Billing Details',
        requirePasswordChange: 'Require Password Change'
      },

      fourOhFour: '4:04',
      fourOhOne: '4:01'
    }
  };
});