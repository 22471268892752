define('timetracker/components/report-parameters', ['exports', 'timetracker/constants', 'moment'], function (exports, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),

    fromDate: null,
    toDate: null,
    showDayNames: false,
    timeZone: null,
    projects: null,
    users: null,
    selectedProjects: [],
    selectedUsers: [],
    reportType: null,
    reportTypes: [_constants.default.JSON, _constants.default.CSV, _constants.default.EXCEL],
    weekStart: _constants.default.FIRST_WEEK_DAY,

    parameterContainerIsShowed: true,

    init: function init() {
      this._super.apply(this, arguments);
      var today = new Date();
      this.setProperties({
        fromMaxDate: today,
        toMaxDate: today,
        timeZone: (0, _moment.default)(today).format('Z')
      });
      this.initializeDefaultParameters();
    },


    submitButtonObserver: Ember.observer('fromDate', 'toDate', function () {
      if (!this.get('fromDate') || !this.get('toDate')) {
        Ember.$('.fetch-report-button').addClass('disabled');
      } else {
        Ember.$('.fetch-report-button').removeClass('disabled');
      }
    }),

    initializeDefaultParameters: function initializeDefaultParameters() {
      var i18n = this.get('i18n');
      var levelOptions = [];
      var levelOptionsConstant = _constants.default.REPORT_LEVEL_OPTIONS;
      levelOptionsConstant.forEach(function (item) {
        levelOptions[item.key] = i18n.t('strings.reports.levelOptions.' + item.key).string;
      });
      var granularityOptionsConstant = _constants.default.GRANULARITY;
      var granularityOptions = [];
      granularityOptionsConstant.forEach(function (item) {
        granularityOptions[item.key] = i18n.t('strings.reports.granularityOptions.' + item.key).string;
      });
      var weekDayOptionsConstant = _constants.default.WEEKDAYS;
      var weekDayOptions = [];
      weekDayOptionsConstant.forEach(function (item) {
        weekDayOptions[item.key] = i18n.t('strings.reports.weekDays.' + item.key).string;
      });
      var roundingTypeConstant = _constants.default.ROUNDING_TYPES;
      var roundingTypeOptions = [];
      roundingTypeConstant.forEach(function (item) {
        roundingTypeOptions[item.key] = i18n.t('strings.reports.roundingTypeOptions.' + item.key).string;
      });
      var timeFormatsConstant = _constants.default.TIME_FORMATS;
      var timeFormatOptions = [];
      timeFormatsConstant.forEach(function (item) {
        timeFormatOptions[item.key] = i18n.t('strings.reports.timeFormats.' + item.key).string;
      });
      this.setProperties({
        levelOptions: levelOptions,
        selectedLevelOne: _constants.default.REPORT_LEVEL_OPTIONS[0].key,
        selectedLevelTwo: _constants.default.REPORT_LEVEL_OPTIONS[1].key,
        selectedLevelThree: _constants.default.REPORT_LEVEL_OPTIONS[2].key, // eslint-disable-line no-magic-numbers
        granularityOptions: granularityOptions,
        selectedGranularity: _constants.default.GRANULARITY[0].key,
        startOfWeekOptions: weekDayOptions,
        selectedStartOfWeek: _constants.default.WEEKDAYS[0].key,
        roundingMinutes: _constants.default.ROUND_OFF_MINUTES,
        selectedRoundingMinutes: _constants.default.ROUND_OFF_MINUTES[0],
        roundingTypes: roundingTypeOptions,
        selectedRoundingType: _constants.default.ROUNDING_TYPES[0].key,
        timeFormats: timeFormatOptions,
        selectedTimeFormat: _constants.default.TIME_FORMATS[0].key
      });
    },


    _fetchReportForSelectedParameters: function _fetchReportForSelectedParameters() {
      var inputData = this.getProperties('selectedGranularity', 'showDayNames', 'selectedStartOfWeek', 'timeZone', 'fromDate', 'toDate', 'projects', 'users', 'selectedUsers', 'selectedProjects', 'selectedLevelOne', 'selectedLevelTwo', 'selectedLevelThree', 'selectedRoundingMinutes', 'selectedRoundingType', 'selectedTimeFormat');
      var queryParams = this.mapInputData(inputData);
      this.sendAction('fetchReport', queryParams, this.get('reportType'));
    },

    mapInputData: function mapInputData(inputData) {

      var parameters = {
        duration: {
          timeZone: inputData.timeZone,
          absolute: {
            startDay: (0, _moment.default)(inputData.fromDate).format('YYYY-MM-DD'),
            endDay: (0, _moment.default)(inputData.toDate).format('YYYY-MM-DD')
          }
        },
        projectIds: inputData.selectedProjects.length > 0 ? inputData.selectedProjects.getEach('id') : null,
        userIds: inputData.selectedUsers.length > 0 ? inputData.selectedUsers.getEach('id') : null,
        levels: [_constants.default.REPORT_LEVEL_OPTIONS.findBy('key', inputData.selectedLevelOne).value, _constants.default.REPORT_LEVEL_OPTIONS.findBy('key', inputData.selectedLevelTwo).value, _constants.default.REPORT_LEVEL_OPTIONS.findBy('key', inputData.selectedLevelThree).value],
        roundOff: {
          intervalInMinutes: inputData.selectedRoundingMinutes,
          roundTo: _constants.default.ROUNDING_TYPES.findBy('key', inputData.selectedRoundingType).value
        },
        timeFormat: _constants.default.TIME_FORMATS.findBy('key', inputData.selectedTimeFormat).value
      };

      // set granularity and related options
      if (inputData.selectedGranularity === _constants.default.GRANULARITY[0].key) {
        parameters.duration.granularity = _constants.default.GRANULARITY[0].value;
        parameters.duration.excludeDayOfWeek = !inputData.showDayNames;
      } else if (inputData.selectedGranularity === _constants.default.GRANULARITY[1].key) {
        parameters.duration.granularity = _constants.default.GRANULARITY[1].value;
        parameters.duration.weekStartsOn = _constants.default.WEEKDAYS.findBy('key', inputData.selectedStartOfWeek).value;
      } else {
        parameters.duration.granularity = _constants.default.GRANULARITY[2].value; // eslint-disable-line no-magic-numbers
      }
      return parameters;
    },


    actions: {

      didSelectFromDate: function didSelectFromDate(selectedDate) {
        this.setProperties({ fromDate: selectedDate, toMinDate: selectedDate, toDate: null, model: null });
      },

      didSelectToDate: function didSelectToDate(selectedDate) {
        this.set('toDate', selectedDate);
      },

      levelOneOptionSelected: function levelOneOptionSelected(selectedLevelOne) {
        var oldValueForLevelOne = this.get('selectedLevelOne');
        this.set('selectedLevelOne', selectedLevelOne);
        var selectedLevelTwo = this.get('selectedLevelTwo');
        var selectedLevelThree = this.get('selectedLevelThree');
        var levelOptions = this.get('levelOptions');
        if (selectedLevelOne === selectedLevelTwo) {
          this.set('selectedLevelTwo', oldValueForLevelOne);
          Ember.$('.report-dropdown.level.two .selection').dropdown('set selected', levelOptions[oldValueForLevelOne]);
        } else if (selectedLevelOne === selectedLevelThree) {
          this.set('selectedLevelThree', oldValueForLevelOne);
          Ember.$('.report-dropdown.level.three .selection').dropdown('set selected', levelOptions[oldValueForLevelOne]);
        }
      },

      levelTwoOptionSelected: function levelTwoOptionSelected(selectedLevelTwo) {
        var oldValueForLevelTwo = this.get('selectedLevelTwo');
        this.set('selectedLevelTwo', selectedLevelTwo);
        var selectedLevelOne = this.get('selectedLevelOne');
        var selectedLevelThree = this.get('selectedLevelThree');
        var levelOptions = this.get('levelOptions');
        if (selectedLevelOne === selectedLevelTwo) {
          this.set('selectedLevelOne', oldValueForLevelTwo);
          Ember.$('.report-dropdown.level.one .selection').dropdown('set selected', levelOptions[oldValueForLevelTwo]);
        } else if (selectedLevelTwo === selectedLevelThree) {
          this.set('selectedLevelThree', oldValueForLevelTwo);
          Ember.$('.report-dropdown.level.three .selection').dropdown('set selected', levelOptions[oldValueForLevelTwo]);
        }
      },

      levelThreeOptionSelected: function levelThreeOptionSelected(selectedLevelThree) {
        var oldValueForLevelThree = this.get('selectedLevelThree');
        this.set('selectedLevelThree', selectedLevelThree);
        var selectedLevelTwo = this.get('selectedLevelTwo');
        var selectedLevelOne = this.get('selectedLevelOne');
        var levelOptions = this.get('levelOptions');
        if (selectedLevelThree === selectedLevelTwo) {
          this.set('selectedLevelTwo', oldValueForLevelThree);
          Ember.$('.report-dropdown.level.two .selection').dropdown('set selected', levelOptions[oldValueForLevelThree]);
        } else if (selectedLevelOne === selectedLevelThree) {
          this.set('selectedLevelOne', oldValueForLevelThree);
          Ember.$('.report-dropdown.level.one .selection').dropdown('set selected', levelOptions[oldValueForLevelThree]);
        }
      },

      getReport: function getReport(reportType) {
        this.send('toggleParametersContainer');
        this.set('reportType', reportType);
        this._fetchReportForSelectedParameters();
      },
      toggleParametersContainer: function toggleParametersContainer() {
        Ember.$('.report-parameters-container').transition('toggle');
        if (this.get('parameterContainerIsShowed')) {
          Ember.$('.parameters-toggle-button i').removeClass('up').addClass('down');
          this.set('parameterContainerIsShowed', false);
        } else {
          Ember.$('.parameters-toggle-button i').removeClass('down').addClass('up');
          this.set('parameterContainerIsShowed', true);
        }
      }
    }

  });
});