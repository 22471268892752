define('timetracker/routes/authenticated/features/reports', ['exports', 'timetracker/utils/analytics', 'timetracker/utils/network-request', 'timetracker/utils/network-error-handler', 'timetracker/constants'], function (exports, _analytics, _networkRequest, _networkErrorHandler, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    metrics: Ember.inject.service(),

    defaultModel: null,

    model: function model() {
      return Ember.RSVP.hash({
        allProjects: this.store.findAll('project'),
        allUsers: this.store.findAll('user')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var allProjects = model.allProjects.filterBy('archived', false).sortBy('name');
      var allUsers = model.allUsers.filterBy('disabled', false).sortBy('name');
      controller.setProperties({ allProjects: allProjects, allUsers: allUsers });
    },


    // will be invoked when the route is exited
    exit: function exit() {
      // for integration with other routes
      // clearing up the model, i.e the table when user leaves this
      // route and comes back again
      this.controller.setProperties({
        model: null,
        reportsData: null,
        reportParameters: null
      });
    },


    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      var self = this;
      Ember.run.next(this, '_layout');
      Ember.$(window).resize(function () {
        self._layout();
      });
    },

    _layout: function _layout() {
      var bodyHeight = Ember.$('body').innerHeight();
      var headerHeight = Ember.$('.application-header').height();
      Ember.$('.reports-container').css('height', bodyHeight - headerHeight);
      var bodyWidth = Ember.$('body').innerWidth();
      var sidebarWidth = Ember.$('.sidebar').width();
      Ember.$('.reports-container').css('width', bodyWidth - sidebarWidth);
    },

    showLoadingTable: function showLoadingTable() {
      Ember.$('.table-loading-state').css('display', 'block');
      Ember.$('.message-body').css('display', 'none');
    },


    actions: {
      didTransition: function didTransition() {
        _analytics.default.sendRouteEnteredEvent(this, this.get('metrics'));
      },
      fetchReport: function fetchReport(parameters, type) {
        var _this = this;

        if (type === _constants.default.JSON) {
          this.showLoadingTable();
          _analytics.default.sendReportRequestEvent(this.get('metrics'));
          var url = _networkRequest.default.buildURL('generateReport');
          var requestParameters = _networkRequest.default.setRequestParameters(url, 'POST', { data: parameters });
          return _networkRequest.default.makeAPIRequest(requestParameters).then(function (response) {
            return _this.controller.setProperties({
              reportsData: response.data,
              reportParameters: parameters
            });
          }, function (reason) {
            Ember.$('.table-loading-state').css('display', 'none');
            return _networkErrorHandler.default.handleErrorResponse(reason, url);
          });
        }
        this.exportReport(parameters, type);
      }
    },

    exportReport: function exportReport(parameters, type) {
      _analytics.default.sendExportReportEvent(this.get('metrics'));
      var url = _networkRequest.default.buildURL('exportReport', { body: JSON.stringify(parameters), reportType: type });
      var a = document.createElement('A');
      a.href = url;
      a.download = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  });
});