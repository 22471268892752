define('timetracker/routes/authenticated/features/timers', ['exports', 'moment', 'ember-uuid', 'timetracker/constants', 'timetracker/utils/recent-timers-aggregate', 'timetracker/utils/analytics'], function (exports, _moment, _emberUuid, _constants, _recentTimersAggregate, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    syncService: Ember.inject.service(),
    metrics: Ember.inject.service(),

    model: function model() {
      var self = this;
      addEventListener(_constants.default.EVENTS.SYNC_COMPLETED, function () {
        return self.updateViewData();
      });
      this.get('syncService').sync();
      return Ember.RSVP.hash({
        allProjects: this.store.peekAll('project'),
        allTimers: this.store.peekAll('timer')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var allProjects = model.allProjects.filterBy('archived', false).sortBy('name');
      var recentTimers = (0, _recentTimersAggregate.default)(this.filterRecent(model.allTimers), allProjects);
      controller.setProperties({ allProjects: allProjects, recentTimers: recentTimers });
    },
    filterRecent: function filterRecent(allTimers) {
      var currentTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _moment.default)().format();

      var notArchivedTimersArray = allTimers.filterBy('archived', false);
      var filtered = [];
      var zoneOffsetMinute = _moment.default.parseZone(currentTime).utcOffset();
      var recentTimerDays = (0, _moment.default)(currentTime).subtract(_constants.default.RECENT_TIMER_DAYS, 'days').startOf('day');
      for (var i = 0, l = notArchivedTimersArray.length; i < l; i++) {
        var record = notArchivedTimersArray[i];
        var endTime = record.get ? record.get('endTime') : record.endTime;
        var end = endTime ? (0, _moment.default)(endTime) : (0, _moment.default)(currentTime);
        end.utcOffset(zoneOffsetMinute);
        if (end.isAfter(recentTimerDays)) {
          filtered.push(notArchivedTimersArray[i]);
        }
      }
      return filtered;
    },


    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      var self = this;
      Ember.run.next(this, '_layout');
      Ember.$(window).resize(function () {
        self._layout();
      });
    },

    _layout: function _layout() {
      var wrapperHeight = Ember.$('.nested-route-wrapper').height();
      var wrapperWidth = Ember.$('.nested-route-wrapper').width();
      Ember.$('.timer-screen-wrapper').css({
        height: wrapperHeight,
        width: wrapperWidth
      });
      var recentTimersContainer = Ember.$('.recent-timers-wrapper');
      if (recentTimersContainer.length > 0) {
        var _topMargin = recentTimersContainer.position().top;
        recentTimersContainer.height(wrapperHeight - _topMargin);
      }
      // positioning the button with absolute positioning
      // margins are found by trial and error
      // relative positioning using jquery .offset() method
      // caused an empty white space to be left at the bottom of screen
      var leftMargin = 61;
      var topMargin = 100;
      var left = Ember.$('body').width() - leftMargin;
      Ember.$('#sidebar-toggle-button').css({ display: 'block', position: 'absolute', top: topMargin, left: left });
    },

    actions: {
      didTransition: function didTransition() {
        _analytics.default.sendRouteEnteredEvent(this, this.get('metrics'));
      },
      timerClicked: function timerClicked(projectSelected) {
        var recentTimersInitialData = this.controller.get('recentTimers');
        var recentTimers = recentTimersInitialData.map(function (item) {
          return Ember.copy(item);
        });
        var activeTimerObject = recentTimers.findBy('timerRunning', true);
        if (activeTimerObject) {
          this._updateTimer(activeTimerObject);
          if (projectSelected.projectId !== activeTimerObject.projectId) {
            this._createTimer(projectSelected);
          }
        } else {
          this._createTimer(projectSelected);
        }
        this.updateViewData();
        this.get('syncService').sync();
      },
      toggleProjectsSidebar: function toggleProjectsSidebar() {
        Ember.$('#projects-sidebar').sidebar('toggle');
      },
      transitionToAdminPage: function transitionToAdminPage() {
        this.transitionTo('authenticated.manage.administration');
      }
    },

    updateViewData: function updateViewData() {
      var modalObject = {
        allProjects: this.store.peekAll('project'),
        allTimers: this.store.peekAll('timer')
      };
      this.setupController(this.controller, modalObject);
    },
    _updateTimer: function _updateTimer(activeTimerObject) {
      var timerRecord = this.store.peekRecord('timer', activeTimerObject.timerId);
      timerRecord.set('endTime', (0, _moment.default)());
    },
    _createTimer: function _createTimer(projectSelected) {
      var projectId = projectSelected.projectId;
      var newTimerId = (0, _emberUuid.v4)();
      var newTimer = {
        id: newTimerId,
        timerId: newTimerId,
        projectId: projectId,
        startTime: (0, _moment.default)(),
        endTime: null,
        archived: false
      };
      var record = this.store.createRecord('timer', newTimer);
      record.set('id', newTimerId);
    }
  });
});