define('timetracker/services/login-service', ['exports', 'timetracker/utils/network-request'], function (exports, _networkRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    isAuthenticated: false,
    user: null,
    organization: null,
    redirectionUrl: null,

    isAdmin: false,

    login: function login(loginCredentials) {
      var data = {
        teamName: loginCredentials.teamName,
        emailId: loginCredentials.emailId,
        password: loginCredentials.password
      };
      var url = _networkRequest.default.buildURL('login');
      return this._sendRequest(url, data);
    },
    logout: function logout() {
      var self = this;
      var url = _networkRequest.default.buildURL('logout');
      var hash = _networkRequest.default.setRequestParameters(url, 'POST');
      return _networkRequest.default.makeAPIRequest(hash).then(function () {
        self.setProperties({
          isAuthenticated: false,
          user: null,
          organization: null,
          redirectionUrl: null,
          isAdmin: false
        });
        self.setUserForReporting();
      });
    },
    validate: function validate() {
      var data = {};
      var url = _networkRequest.default.buildURL('validate');
      return this._sendRequest(url, data);
    },
    _sendRequest: function _sendRequest(url, data) {
      var hash = _networkRequest.default.setRequestParameters(url, 'POST', { data: data });
      var self = this;
      return _networkRequest.default.makeAPIRequest(hash).then(function (response) {
        var user = response.user;
        self.set('isAuthenticated', true);
        self.set('user', user);
        self.set('organization', response.organization);
        if (user.canManageProjects || user.canManageUsers || user.canChangeBillingDetails) {
          self.set('isAdmin', true);
        } else {
          self.set('isAdmin', false);
        }
        self.setUserForReporting(user);
        return response;
      }, function (reason) {
        return reason;
      });
    },
    setUserForReporting: function setUserForReporting(user) {
      if (user) {
        Raven.setUserContext({ // eslint-disable-line no-undef
          userId: user.userId,
          userName: user.name,
          userEmailId: user.emailId
        });
      } else {
        Raven.setUserContext(); // eslint-disable-line no-undef
      }
    }
  });
});