define('timetracker/routes/authenticated/manage/administration', ['exports', 'timetracker/utils/network-request', 'timetracker/utils/analytics', 'timetracker/utils/network-error-handler', 'timetracker/constants', 'timetracker/utils/logger'], function (exports, _networkRequest, _analytics, _networkErrorHandler, _constants, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    loginService: Ember.inject.service('loginService'),
    metrics: Ember.inject.service(),
    i18n: Ember.inject.service(),

    model: function model(params, transition) {
      var _this = this;

      var loginService = this.get('loginService');
      var store = this.store;
      return loginService.validate().then(function () {
        if (!loginService.isAdmin) {
          return _this.replaceWith('unauthorized');
        }
        return Ember.RSVP.hash({
          projects: _this.fetchAllProjects(store, loginService),
          users: _this.fetchAllUsers(store, loginService)
        }).catch(function (reason) {
          _networkErrorHandler.default.broadcastCustomEvent(reason);
          return transition.abort();
        });
      }).catch(function (reason) {
        _networkErrorHandler.default.broadcastCustomEvent(reason);
        return transition.abort();
      });
    },
    exit: function exit() {
      Ember.$('.admin-access-denied-error.ui.modal, .admin-page-custom-error.ui.modal').remove();
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('user', this.get('loginService').user);
    },
    fetchAllProjects: function fetchAllProjects(store, loginService) {
      if (loginService.user.canManageProjects) {
        var url = _networkRequest.default.buildURL('allProjects');
        var params = _networkRequest.default.setRequestParameters(url, 'GET');
        return _networkRequest.default.makeAPIRequest(params).then(function (response) {
          response.projects.forEach(function (project) {
            store.push(store.normalize('admin-project', project));
          });
          if (!loginService.user.canManageUsers) {
            response.users.forEach(function (user) {
              store.push(store.normalize('admin-user', user));
            });
          }
          return store.peekAll('admin-project');
        }, function (reason) {
          return reason;
        });
      }
      return null;
    },
    fetchAllUsers: function fetchAllUsers(store, loginService) {
      if (loginService.user.canManageUsers) {
        var url = _networkRequest.default.buildURL('allUsers');
        var params = _networkRequest.default.setRequestParameters(url, 'GET');
        return _networkRequest.default.makeAPIRequest(params).then(function (response) {
          response.users.forEach(function (project) {
            store.push(store.normalize('admin-user', project));
          });
          return store.peekAll('admin-user');
        }, function (reason) {
          return reason;
        });
      } else if (loginService.user.canManageProjects) {
        return store.peekAll('admin-user');
      }
      return null;
    },


    actions: {
      didTransition: function didTransition() {
        _analytics.default.sendRouteEnteredEvent(this, this.get('metrics'));
      },
      handleError: function handleError(error) {
        this.handleFourHundredError(error);
      }
    },

    handleFourHundredError: function handleFourHundredError(adapterError) {
      var statusCode = _networkErrorHandler.default.getErrorStatus(adapterError);
      if (statusCode === _constants.default.RESPONSE_CODES.BAD_REQUEST) {
        var errorCode = _networkErrorHandler.default.getFouHundredErrorCode(adapterError);
        switch (errorCode) {
          case _constants.default.RESPONSE_400_ERRORS.CONFLICT:
            {
              this._showCustomErrorModal(errorCode);
              this.refresh();
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.PROJECT_ID_ALREADY_EXISTS:
            {
              // TODO update project id with a new id and retry
              _logger.default.error('Encountered project id already exist error');
              this._showCustomErrorModal(errorCode);
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.PROJECT_NAME_ALREADY_EXISTS:
            {
              this._showCustomErrorModal(errorCode);
              this.refresh();
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.USER_NOT_AUTHORIZED_TO_MANAGE_PROJECTS:
            {
              this._showAccessDeniedModal();
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.MISSING_USER_IDS:
            {
              _logger.default.error('Encountered missing user ids error');
              this._showCustomErrorModal(errorCode);
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.USER_DOES_NOT_EXIST:
            {
              _logger.default.error('Encountered user does not exist error');
              this._showCustomErrorModal(errorCode);
              this.refresh();
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.USER_ID_ALREADY_EXISTS:
            {
              // TODO update user id with a new id and retry
              _logger.default.error('Encountered user id already exist error');
              this._showCustomErrorModal(errorCode);
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.USER_WITH_SAME_EMAIL_ID_ALREADY_EXISTS:
            {
              this._showCustomErrorModal(errorCode);
              this.refresh();
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.USER_NOT_AUTHORIZED_TO_MANAGE_USERS:
            {
              this._showAccessDeniedModal();
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.AT_LEAST_ONE_USER_SHOULD_BE_MANAGING_USERS:
            {
              this._showCustomErrorModal(errorCode);
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.AT_LEAST_ONE_USER_SHOULD_BE_MANAGING_PROJECTS:
            {
              this._showCustomErrorModal(errorCode);
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.AT_LEAST_ONE_USER_SHOULD_BE_ABLE_TO_CHANGE_BILLING_DETAILS:
            {
              this._showCustomErrorModal(errorCode);
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.CANNOT_CHANGE_EMAIL_ID:
            {
              _logger.default.error('Encountered can not change email id error');
              this._showCustomErrorModal(errorCode);
              break;
            }
          default:
            {
              this._showCustomErrorModal(errorCode);
            }
        }
      }
    },
    _showAccessDeniedModal: function _showAccessDeniedModal() {
      var self = this;
      Ember.$('.admin-access-denied-error.ui.modal').modal({
        closable: false,
        onApprove: function onApprove() {
          Ember.$('.admin-access-denied-error.ui.modal').hide();
          self.transitionTo('login');
        }
      }).modal('show');
    },
    _showCustomErrorModal: function _showCustomErrorModal(errorCode) {
      var message = void 0;
      var i18n = this.get('i18n');
      if (errorCode === _constants.default.RESPONSE_400_ERRORS.AT_LEAST_ONE_USER_SHOULD_BE_MANAGING_USERS) {
        message = i18n.t('messages.atLeastOneUserShouldManageUsers');
      } else if (errorCode === _constants.default.RESPONSE_400_ERRORS.AT_LEAST_ONE_USER_SHOULD_BE_MANAGING_PROJECTS) {
        message = i18n.t('messages.atLeastOneUserShouldManageProjects');
      } else if (errorCode === _constants.default.RESPONSE_400_ERRORS.AT_LEAST_ONE_USER_SHOULD_BE_ABLE_TO_CHANGE_BILLING_DETAILS) {
        message = i18n.t('messages.atLeastOneUserShouldBeAbleToChangeBilling');
      } else {
        message = i18n.t('messages.pleaseTryLater');
      }
      this.controller.set('errorMessage', message);
      Ember.$('.admin-page-custom-error.ui.modal').modal({
        onApprove: function onApprove() {
          Ember.$('.admin-page-custom-error.ui.modal').hide();
        }
      }).modal('show');
    }
  });
});