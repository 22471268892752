define('timetracker/serializers/user-report-timer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({

    // each model in ember store is specified by 'id' attribute
    // we change this attribute for user-report-timer model to be 'timerId'
    primaryKey: 'timerId',

    /**
     Ember data expects the adapter to return JSONAPI formatted response.
     Response from adapter is normalized into JSONAPI format, using normalizeResponse
     @method normalizeResponse
     @param {DS.Store} store the store class
     @param {object} modelObject model object for user-report-timer
     @param {object} payload response data from adapter
     @param {string} id identifier for response in case of single object
     @param {string} responseType the type of store method that invoked the method
     @return {object} JSONAPI formatted object.
     */
    normalizeResponse: function normalizeResponse(store, modelObject, payload, id, responseType) {
      if (responseType === 'query') {
        return this.normalizeQueryResponse(store, modelObject, payload, id, responseType);
      }
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, modelObject, payload, id, responseType) {
      if (payload.errors) {
        return { data: [] };
      }
      var timers = payload.timers;
      return this.normalizeArrayResponse(store, modelObject, timers, id, responseType);
    }
  });
});