define('timetracker/utils/timer-util', ['exports', 'moment', 'timetracker/utils/logger'], function (exports, _moment, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var timerUtil = {
    testAndArchiveLessThanAMinuteTimers: function testAndArchiveLessThanAMinuteTimers(timer) {
      var end = (0, _moment.default)(timer.get('endTime'));
      if (!end.isValid()) {
        return;
      }
      var start = (0, _moment.default)(timer.get('startTime'));
      var duration = end.diff(start, 'minute');
      if (duration < 1) {
        timer.set('archived', true);
      }
    },
    getJsonFormattedTimer: function getJsonFormattedTimer(storeRecord) {
      return {
        startTime: (0, _moment.default)(storeRecord.get('startTime')).format(),
        endTime: storeRecord.get('endTime') ? (0, _moment.default)(storeRecord.get('endTime')).format() : null,
        archived: storeRecord.get('archived'),
        projectId: storeRecord.get('projectId'),
        changeToken: storeRecord.get('changeToken')
      };
    },
    isAValidTimer: function isAValidTimer(jsonTimer) {
      if (!jsonTimer.endTime) {
        return true;
      }
      // A timer with end time before start time is not be kept in store
      // Throw an error if timer's start/end exceeds current time
      var currentTime = (0, _moment.default)().endOf('minute'); // avoids problems where devices are off by few seconds
      var startTime = (0, _moment.default)(jsonTimer.startTime);
      var endTime = (0, _moment.default)(jsonTimer.endTime);
      if (startTime.isAfter(currentTime)) {
        _logger.default.error('Start time after current time error', jsonTimer);
      }
      if (endTime.isAfter(currentTime)) {
        _logger.default.error('End time after current time error', jsonTimer);
      }
      if (startTime.isAfter(endTime)) {
        _logger.default.error('Start time after end time error', jsonTimer);
        return false;
      }
      return true;
    }
  };

  exports.default = timerUtil;
});