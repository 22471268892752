define('timetracker/components/new-timer-list', ['exports', 'timetracker/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    projectListViewData: null,
    searchText: null,

    i18n: Ember.inject.service(),
    loginService: Ember.inject.service(),

    actions: {
      pushProjectsButtonLeft: function pushProjectsButtonLeft() {
        Ember.$('#sidebar-toggle-button').removeClass('moving-button-out');
        Ember.$('#sidebar-toggle-button').addClass('moving-button-in');
      },
      pushProjectsButtonRight: function pushProjectsButtonRight() {
        Ember.$('#sidebar-toggle-button').removeClass('moving-button-in');
        Ember.$('#sidebar-toggle-button').addClass('moving-button-out');
      }
    },

    didRender: function didRender() {
      this.createProjectList();
    },

    willDestroy: function willDestroy() {
      Ember.$(this.get('element')).empty();
    },
    willUpdate: function willUpdate() {
      Ember.$('.sidebar-projects-list').empty();
    },
    _startNewTimer: function _startNewTimer(project) {
      var selectedProject = {
        projectId: project.get('id'),
        name: project.get('name'),
        colorCode: project.get('colorCode')
      };
      this.sendAction('timerClicked', selectedProject);
      Ember.$('#projects-sidebar').sidebar('hide');
    },


    createProjectList: function createProjectList() {
      var sidebarMenu = Ember.$('.sidebar-projects-list');
      var projects = this.get('allProjects');
      var i18n = this.get('i18n');
      var self = this;
      if (projects.length === 0) {
        if (this.get('loginService').user.canManageProjects) {
          sidebarMenu.append(this.createTagForMessage(i18n.t('messages.emptyProjectsAdminMessage'), 'div'));
          var link = this.createTagForMessage(i18n.t('messages.emptyProjectsAdminLink'), 'a');
          Ember.$(link).click(function () {
            self.sendAction('transitionToAdminPage');
          });
          return sidebarMenu.append(link);
        }
        return sidebarMenu.append(this.createTagForMessage(i18n.t('messages.emptyProjectsNonAdmin'), 'div'));
      }
      var searchText = this.get('searchText');
      var filteredProjects = void 0;
      if (searchText) {
        var searchExpression = new RegExp(this.get('searchText'), 'i');
        filteredProjects = projects.filter(function (item) {
          return item.get('name').search(searchExpression) >= 0;
        });
        if (filteredProjects.length === 0) {
          return sidebarMenu.append(this.createTagForMessage(i18n.t('messages.emptyProjectsSearch'), 'div'));
        }
      } else {
        filteredProjects = projects;
      }

      var _loop = function _loop(i, l) {
        var record = filteredProjects[i];
        var div = document.createElement('div');
        var divRef = Ember.$(div);
        var projectColor = _constants.default.PROJECT_COLORS[record.get('colorCode')];
        divRef.addClass('projects-list-item');
        divRef.prop('title', 'start timer for ' + record.get('name'));
        var colorBox = document.createElement('div');
        var colorBoxRef = Ember.$(colorBox);
        colorBoxRef.addClass('projects-sidebar-list-color-box');
        colorBoxRef.css('backgroundColor', projectColor);
        div.appendChild(colorBox);
        var projectName = document.createTextNode(record.get('name'));
        var span = document.createElement('span');
        var spanRef = Ember.$(span);
        spanRef.addClass('project-sidebar-list-text');
        span.appendChild(projectName);
        div.appendChild(span);
        div.onclick = function () {
          self._startNewTimer(record);
        };
        sidebarMenu.append(div);
      };

      for (var i = 0, l = filteredProjects.length; i < l; i++) {
        _loop(i, l);
      }
    },

    createTagForMessage: function createTagForMessage(message) {
      var tagType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'div';

      var tag = document.createElement(tagType);
      var tagRef = Ember.$(tag);
      tagRef.addClass('empty-projects-message');
      tagRef.append(document.createTextNode(message));
      return tag;
    }
  });
});