define('timetracker/components/timeline-view-component', ['exports', 'moment', 'ember-uuid', 'timetracker/constants', 'timetracker/utils/timeline-util', 'timetracker/utils/logger', 'timetracker/utils/analytics'], function (exports, _moment, _emberUuid, _constants, _timelineUtil, _logger, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var setIntervalId = void 0;
  var timeoutId = void 0;

  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),
    store: Ember.inject.service('store'),
    metrics: Ember.inject.service(),
    syncService: Ember.inject.service(),
    weekStart: _constants.default.FIRST_WEEK_DAY,

    // month view is currently not enabled
    // we can do it so just by adding 'month' text in header below
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'agendaWeek,agendaDay'
    },

    buttonText: null,

    validRange: {
      end: (0, _moment.default)().add(1, 'day').format('YYYY-MM-DD')
    },
    today: new Date(),

    views: {
      agendaWeek: {
        columnFormat: 'ddd D',
        titleFormat: 'MMMM D, YYYY'
      },
      month: {
        editable: false,
        selectable: false
      }
    },

    modalMessage: null,
    createUpdateErrorMessage: null,

    modalInput: {
      projectName: null,
      projectId: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      startDateTime: null,
      endDateTime: null
    },
    inputParameters: {
      maxDate: null,
      minEndDate: null,
      maxStartTime: null,
      maxEndTime: null,
      minEndTime: null
    },

    projects: null,
    events: null,

    inputChanged: Ember.observer('modalInput.projectId', 'modalInput.startDate', 'modalInput.endDate', 'modalInput.startTime', 'modalInput.endTime', function () {
      this.handleChangeInModalInputs();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var i18n = this.get('i18n');
      var buttonText = {
        month: i18n.t('strings.timeline.month'),
        agendaDay: i18n.t('strings.timeline.day'),
        agendaWeek: i18n.t('strings.timeline.week'),
        today: i18n.t('strings.timeline.today')
      };
      this.set('buttonText', buttonText);
    },
    didInsertElement: function didInsertElement() {
      Ember.$('.fc-today-button').before(Ember.$('.go-to-date'));
      Ember.$('.fc-today-button').after(Ember.$('.add-button'));
    },
    willDestroyElement: function willDestroyElement() {
      // clear up the model or else multiple instances of them tend to exist on re-render
      Ember.$('.timeline-create-timer.ui.modal, .timeline-confirmation-modal.ui.modal').remove();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.updateViewAfterSync();
    },


    actions: {
      goToDate: function goToDate(date) {
        if (date) {
          Ember.$('.full-calendar').fullCalendar('gotoDate', (0, _moment.default)(date));
          Ember.$('.go-to-date-picker').val('');
        }
      },
      didClickAddButton: function didClickAddButton() {
        var start = void 0;
        var end = void 0;
        var defaultHour = 9;
        var day = Ember.$('.full-calendar').fullCalendar('getDate');
        // if it's current day, default start is 1 hour before current time
        if (day.isSame((0, _moment.default)(), 'day')) {
          start = (0, _moment.default)().subtract(1, 'hour');
          end = (0, _moment.default)();
        } else {
          // rest of the days have 'start' as 9 AM and 'end' as 10 AM defaults
          start = (0, _moment.default)(day.format()).set('hour', defaultHour);
          end = (0, _moment.default)(day.format()).set('hour', defaultHour).add(1, 'hour');
        }
        var timer = {
          start: start,
          end: end
        };
        this._openInputModal(timer, false);
      },
      viewRender: function viewRender(view) {
        var viewType = view.type;
        this.clearRunningTimerFromFC();
        if (viewType === 'agendaDay') {
          this.showTotalTimeInDayView((0, _moment.default)(view.start.format()));
          Ember.$('.month-navigation').css('display', 'none');
        } else if (viewType === 'agendaWeek') {
          this.showTotalTimeInWeekView((0, _moment.default)(view.start.format()), (0, _moment.default)(view.end.format()));
          Ember.$('.month-navigation').css('display', 'none');
        }
      },
      eventRender: function eventRender(event, element) {
        var title = element.find('.fc-title');
        var duration = document.createElement('div');
        var durationMinutes = event.end.diff(event.start, 'minutes');
        // minimum duration to show up in timeline is 45 minutes
        // Value is found by trial and error
        if (durationMinutes >= 45) {
          // eslint-disable-line no-magic-numbers
          Ember.$(duration).append(document.createTextNode(_timelineUtil.default.getHourMinuteStringFromMinutes(durationMinutes, this.get('i18n'))));
          title.after(duration);
        }
        if (event.isRunning) {
          this.setRunningTimerInFC(event);
        }
      },
      dayClick: function dayClick(date, jsEvent, view) {
        if (view.type === 'month') {
          Ember.$('.full-calendar').fullCalendar('changeView', 'agendaDay', date);
        }
      },
      eventClick: function eventClick(timer, jsEvent, view) {
        if (view.type === 'month') {
          return Ember.$('.full-calendar').fullCalendar('changeView', 'agendaDay', timer.start);
        } else if (view.type === 'agendaWeek' || view.type === 'agendaDay') {
          this.showTimerDetailsPopup(timer, jsEvent);
        }
      },
      eventAllow: function eventAllow(updatedLocation) {
        return updatedLocation.end.diff(updatedLocation.start, 'minutes') >= 1 && (0, _moment.default)().isSameOrAfter((0, _moment.default)(updatedLocation.end.format()));
      },
      selectAllow: function selectAllow(timerLocation) {
        if ((0, _moment.default)().isSameOrBefore((0, _moment.default)(timerLocation.start.format()))) {
          return false;
        }
        if ((0, _moment.default)().isSameOrAfter((0, _moment.default)(timerLocation.end.format()))) {
          return true;
        }
        Ember.$('.full-calendar').fullCalendar('select', timerLocation.start, (0, _moment.default)());
        return false;
      },
      eventResize: function eventResize(updatedTimer, delta, revertFunction) {
        this._executeUpdateTimerEvent(updatedTimer, revertFunction);
        _analytics.default.sendTimerEditedEventViaDragging(this.get('metrics'));
      },
      eventDrop: function eventDrop(updatedTimer, delta, revertFunction) {
        this._executeUpdateTimerEvent(updatedTimer, revertFunction);
        _analytics.default.sendTimerEditedEventViaDragging(this.get('metrics'));
      },
      select: function select(start, end) {
        var timer = {
          start: start,
          end: end
        };
        this._openInputModal(timer, false);
      },
      didSelectStartDate: function didSelectStartDate(selectedDate) {
        this.setProperties({ 'modalInput.startDate': selectedDate });
      },
      didSelectEndDate: function didSelectEndDate(selectedDate) {
        this.setProperties({ 'modalInput.endDate': selectedDate });
      },
      clearEndTime: function clearEndTime() {
        this.setProperties({ 'modalInput.endDate': null, 'modalInput.endTime': null });
      },
      didSelectProjectName: function didSelectProjectName(selectedProject) {
        this.set('modalInput.projectName', selectedProject.get('name'));
        this.set('modalInput.projectId', selectedProject.get('id'));
      },
      setEndTime: function setEndTime(time) {
        this.set('modalInput.endTime', time);
      },
      setStartTime: function setStartTime(time) {
        this.set('modalInput.startTime', time);
      },
      eventResizeStart: function eventResizeStart() {
        this.sendAction('shouldAllowSync', false);
      },
      eventResizeStop: function eventResizeStop() {
        this.sendAction('shouldAllowSync', true);
      },
      eventDragStart: function eventDragStart() {
        this.sendAction('shouldAllowSync', false);
      },
      eventDragStop: function eventDragStop() {
        this.sendAction('shouldAllowSync', true);
      }
    },

    handleChangeInModalInputs: function handleChangeInModalInputs() {
      var startDate = (0, _moment.default)(this.get('modalInput.startDate'));
      var endDate = (0, _moment.default)(this.get('modalInput.endDate'));
      var startTime = this.get('modalInput.startTime');
      var endTime = this.get('modalInput.endTime');
      var projectId = this.get('modalInput.projectId');
      var currentTime = (0, _moment.default)();
      var i18n = this.get('i18n');
      this.setModalMessage(null);
      var startDateTime = Ember.$.fullCalendar.moment(startDate);
      if (!startDate.isValid() && !startTime) {
        // this will be observed when we are clearing the dates in modal, so just ignore it
        return;
      }
      startDateTime.set('hour', startTime.split(':')[0]);
      startDateTime.set('minute', startTime.split(':')[1]);
      if (startDateTime.isAfter(currentTime)) {
        this.setModalButtonState(false);
        return this.setModalMessage(i18n.t('messages.startAfterCurrent'));
      }
      var endDateTime = Ember.$.fullCalendar.moment(endDate);
      if (endDateTime.isValid()) {
        if (endTime === null) {
          return this.setModalButtonState(false);
        }
        endDateTime.set('hour', endTime.split(':')[0]);
        endDateTime.set('minute', endTime.split(':')[1]);
        if (endDateTime.isAfter(currentTime)) {
          this.setModalButtonState(false);
          return this.setModalMessage(i18n.t('messages.endAfterCurrent'));
        } else if (startDateTime.isSameOrAfter(endDateTime, 'minute')) {
          this.setModalButtonState(false);
          return this.setModalMessage(i18n.t('messages.startAfterEnd'));
        }
      } else if (endTime) {
        return this.setModalButtonState(false);
      }
      if (!projectId) {
        return this.setModalButtonState(false);
      }
      this.setModalButtonState(true);
    },
    setModalButtonState: function setModalButtonState(shouldEnable) {
      if (shouldEnable) {
        Ember.$('.timeline-create-timer .approve.button').removeClass('disabled');
      } else {
        Ember.$('.timeline-create-timer .approve.button').addClass('disabled');
      }
    },
    setModalMessage: function setModalMessage(message) {
      this.set('createUpdateErrorMessage', message);
    },
    changeToDayViewForGivenDay: function changeToDayViewForGivenDay(date) {
      Ember.$('.full-calendar').fullCalendar('changeView', 'agendaDay', date);
    },
    getFcEvents: function getFcEvents() {
      return Ember.$('.full-calendar').fullCalendar('clientEvents');
    },
    incrementMinute: function incrementMinute(timer) {
      timer.end.add(1, 'minute');
      Ember.$('.full-calendar').fullCalendar('updateEvent', timer);
      this.updateTotalTime();
    },
    setRunningTimerInFC: function setRunningTimerInFC(timer) {
      var _this = this;

      var initialTimerMS = (60 - timer.end.diff(timer.start, 'seconds') % 60) * 1000; // eslint-disable-line
      var intervalTimerMS = _constants.default.TIMER_UPDATE_MS;
      this.clearRunningTimerFromFC();
      timeoutId = Ember.run.later(this, function () {
        _this.incrementMinute(timer);
        setIntervalId = setInterval(function () {
          return _this.incrementMinute(timer);
        }, intervalTimerMS);
      }, initialTimerMS);
    },
    clearRunningTimerFromFC: function clearRunningTimerFromFC() {
      Ember.run.cancel(timeoutId);
      clearInterval(setIntervalId);
    },
    showTotalTimeInWeekView: function showTotalTimeInWeekView(start, end) {
      var allTimers = this.getFcEvents();
      var noOfDays = 7;
      var filteredTimers = [];
      var timerEndTime = void 0;
      var timerStartTime = void 0;
      for (var i = 0, l = allTimers.length; i < l; i++) {
        timerEndTime = allTimers[i].end;
        timerStartTime = allTimers[i].start;
        if (!(timerStartTime.isSameOrAfter(end) || timerEndTime.isSameOrBefore(start))) {
          filteredTimers.push(allTimers[i]);
        }
      }
      var currentDay = void 0;
      var targetElement = void 0;
      var formattedTime = void 0;
      for (var _i = 0; _i < noOfDays; _i++) {
        currentDay = start.clone().add(_i, 'day');
        var totalMinutes = _timelineUtil.default.getTotalMinutesForTheDay(currentDay, filteredTimers);
        formattedTime = _timelineUtil.default.getWeekViewFormattedTime(totalMinutes);
        targetElement = _timelineUtil.default.getTargetElementInWeekViewForTotalTime(_i);
        _timelineUtil.default.renderTotalTimeInFcHeader(formattedTime, targetElement);
      }
    },
    showTotalTimeInDayView: function showTotalTimeInDayView(startTime) {
      var i18n = this.get('i18n');
      var timers = this.getFcEvents();
      var totalMinutesForTheDay = _timelineUtil.default.getTotalMinutesForTheDay(startTime, timers);
      var formattedTime = _timelineUtil.default.getHourMinuteStringFromMinutes(totalMinutesForTheDay, i18n);
      formattedTime = formattedTime ? formattedTime : i18n.t('messages.noTimersRunning');
      var targetHeader = '.fc-agendaDay-view .fc-day-header.fc-widget-header';
      _timelineUtil.default.renderTotalTimeInFcHeader(formattedTime, targetHeader);
    },
    updateTotalTime: function updateTotalTime() {
      var view = Ember.$('.full-calendar').fullCalendar('getView');
      if (view.type === 'agendaDay') {
        this.showTotalTimeInDayView((0, _moment.default)(view.start.format()));
      } else if (view.type === 'agendaWeek') {
        this.showTotalTimeInWeekView((0, _moment.default)(view.start.format()), (0, _moment.default)(view.end.format()));
      }
    },
    _executeCreateTimerEvent: function _executeCreateTimerEvent(timer, project) {
      var calendarRef = Ember.$('.full-calendar');
      var self = this;
      timer.projectId = project.get('id');
      timer.archived = false;
      var successFunction = function successFunction() {
        var newTimer = self._createTimerInStore(timer.start, timer.end, project.get('id'));
        var timerFC = _timelineUtil.default.createFcEvent(newTimer, project.get('colorCode'), project.get('name'));
        if (timerFC.isRunning) {
          timerFC.end = Ember.$.fullCalendar.moment();
          self.setRunningTimerInFC(timerFC);
        }
        calendarRef.fullCalendar('renderEvent', timerFC, true);
        self.updateTotalTime();
        self.get('syncService').sync();
      };
      var revertFunction = function revertFunction() {
        return calendarRef.fullCalendar('unselect');
      };
      this._checkOverlappingTimersAndExecuteUserConfirmation(timer, successFunction, revertFunction);
    },
    _executeUpdateTimerEvent: function _executeUpdateTimerEvent(updatedTimer, revertFunction) {
      var self = this;
      var successFunction = function successFunction() {
        if (updatedTimer.isRunning && updatedTimer.end.isValid()) {
          updatedTimer.isRunning = false;
          self.clearRunningTimerFromFC();
        }
        self._updateTimerInStore(updatedTimer);
        if (updatedTimer.isRunning) {
          updatedTimer.end = Ember.$.fullCalendar.moment();
          self.setRunningTimerInFC(updatedTimer);
        }
        Ember.$('.full-calendar').fullCalendar('updateEvent', updatedTimer);
        self.updateTotalTime();
        self.get('syncService').sync();
      };
      this._checkOverlappingTimersAndExecuteUserConfirmation(updatedTimer, successFunction, revertFunction);
    },
    updateViewAfterSync: function updateViewAfterSync() {
      this.clearRunningTimerFromFC();
      var events = this.get('events');
      Ember.$('.full-calendar').fullCalendar('removeEventSources');
      Ember.$('.full-calendar').fullCalendar('addEventSource', events);
      this.updateTotalTime();
    },
    _checkOverlappingTimersAndExecuteUserConfirmation: function _checkOverlappingTimersAndExecuteUserConfirmation(timer, successFunction, revertFunction) {
      var overlappingTimers = _timelineUtil.default.getOverlappingTimers(timer);
      var self = this;
      var i18n = this.get('i18n');
      this.set('cancelArchiveButtonText', i18n.t('strings.timeline.buttons.continue'));
      if (overlappingTimers.length > 0) {
        this.set('modalMessage', i18n.t('messages.overlappingTimersConfirmation'));
        Ember.$('.timeline-confirmation-modal.ui.modal').modal({
          closable: false,
          onApprove: function onApprove() {
            self.updateOverlappingTimers(overlappingTimers, timer);
            successFunction();
          },
          onDeny: function onDeny() {
            revertFunction();
          }
        }).modal('show');
      } else if (overlappingTimers.length === 0) {
        successFunction();
      }
    },
    updateOverlappingTimers: function updateOverlappingTimers(overlappingTimers, timerToTestAgainst) {
      var start = timerToTestAgainst.start;
      var end = timerToTestAgainst.end.isValid() ? timerToTestAgainst.end : (0, _moment.default)();
      var calendarUiRef = Ember.$('.full-calendar');
      var overlappingTimer = void 0;
      var archived = void 0;
      for (var i = 0, l = overlappingTimers.length; i < l; i++) {
        overlappingTimer = overlappingTimers[i];
        archived = false;
        if (end.isBefore(overlappingTimer.end)) {
          if (start.isAfter(overlappingTimer.start)) {
            var newFcTimer = this._partitionExistingTimer(overlappingTimer, start);
            calendarUiRef.fullCalendar('renderEvent', newFcTimer, true);
          }
          // update overlapping timers start time
          overlappingTimer.start = (0, _moment.default)(end);
          calendarUiRef.fullCalendar('updateEvent', overlappingTimer);
        } else if (start.isAfter(overlappingTimer.start)) {
          // update end time for overlapping timer
          overlappingTimer.isRunning = false;
          overlappingTimer.end = (0, _moment.default)(start);
          calendarUiRef.fullCalendar('updateEvent', overlappingTimer);
        } else {
          // archive completely overlapped timer
          archived = true;
          calendarUiRef.fullCalendar('removeEvents', overlappingTimer.id);
        }
        this._updateTimerInStore(overlappingTimer, archived);
      }
    },
    _partitionExistingTimer: function _partitionExistingTimer(timerToPartitionFrom, endTimeForNewTimer) {
      var projectId = timerToPartitionFrom.projectId;
      var project = this.get('store').peekRecord('project', projectId);
      var newTimer = this._createTimerInStore(timerToPartitionFrom.start, endTimeForNewTimer, projectId);
      return _timelineUtil.default.createFcEvent(newTimer, project.get('colorCode'), timerToPartitionFrom.title);
    },
    _createTimerInStore: function _createTimerInStore(startTime, endTime, projectId) {
      var store = this.get('store');
      var timerId = (0, _emberUuid.v4)();
      var newTimer = {
        id: timerId,
        timerId: timerId,
        startTime: (0, _moment.default)(startTime),
        endTime: (0, _moment.default)(endTime).isValid() ? (0, _moment.default)(endTime) : null,
        projectId: projectId,
        archived: false
      };
      store.createRecord('timer', newTimer);
      return newTimer;
    },
    _updateTimerInStore: function _updateTimerInStore(timerToUpdate) {
      var archived = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      // timer is a fullCalendar event object
      var store = this.get('store');
      var timerRecord = store.peekRecord('timer', timerToUpdate.id);
      var endTime = void 0;
      if (timerToUpdate.isRunning || !timerToUpdate.end.isValid()) {
        if (archived === true) {
          endTime = (0, _moment.default)();
        } else {
          endTime = null;
        }
      } else {
        endTime = (0, _moment.default)(timerToUpdate.end);
      }
      timerRecord.setProperties({
        startTime: timerToUpdate.start,
        endTime: endTime,
        archived: archived
      });
    },
    showTimerDetailsPopup: function showTimerDetailsPopup(timer, element) {
      var targetElement = Ember.$(element.currentTarget);
      var closePopup = function closePopup() {
        Ember.$(document).off('.popupHide');
        Ember.$('.fc-scroller').off('.popupHide');
        if (Ember.$('.timeline-popup-copy').length > 0) {
          targetElement.popup('destroy');
        }
      };
      targetElement.popup({
        exclusive: true,
        boundary: '.full-calendar',
        context: '.full-calendar',
        lastResort: true,
        on: 'manual',
        onVisible: function onVisible() {
          // hide popup on click outside popup
          Ember.$(document).on('mousedown.popupHide', closePopup);

          // hide popup on scrolling timeline
          Ember.$('.fc-scroller').on('scroll.popupHide', closePopup);

          // prevent popup hide on inside click
          Ember.$('.timeline-popup-copy').mousedown(function (e) {
            if (!Ember.$(e.target).is('.popup-close-icon')) {
              e.stopPropagation();
            }
          });

          // hide popup on ESC key pressed
          Ember.$(document).on('keydown.popupHide', function (e) {
            var escKeyCode = 27;
            if (e.keyCode === escKeyCode) {
              closePopup();
            }
          });
        },

        html: this._getPopupContents(timer, targetElement)
      }).popup('show');
    },
    _getPopupContents: function _getPopupContents(timer, targetElement) {

      /* here if we directly use the popup, the semantic-ui will reposition the popup next ti the element the
      popup belongs to. So when we navigate through calendar the popup is removed with the event it was
      attached to causing serious errors. As such I decided to use a copy of it every time I need one. */
      var i18n = this.get('i18n');
      this.set('cancelArchiveButtonText', i18n.t('strings.timeline.buttons.archive'));
      var popupCopy = Ember.$('.timeline-popup').clone();
      popupCopy.addClass('timeline-popup-copy');
      popupCopy.removeClass('timeline-popup');
      var title = popupCopy.find('.popup-project-name');
      title.append(document.createTextNode(timer.title));
      var start = popupCopy.find('.popup-start');
      start.append(document.createTextNode(timer.start.format('llll')));
      var end = popupCopy.find('.popup-end');
      var duration = popupCopy.find('.popup-duration');
      var endTime = void 0;
      if (timer.isRunning) {
        endTime = i18n.t('strings.modal.currentlyRunning');
      } else {
        // https://github.com/gps/TimeTracker-PM/issues/462
        if (!timer.end) {
          // This should not happen as a timer should have an end time if it is not a running timer
          _logger.default.error('A non running timer in timeline with no end time detected', timer);
        }
        endTime = timer.end.format('llll');
      }
      var durationMinutes = timer.end.diff(timer.start, 'minutes');
      duration.append(document.createTextNode(_timelineUtil.default.getHourMinuteStringFromMinutes(durationMinutes, i18n)));
      end.append(document.createTextNode(endTime));
      var self = this;
      popupCopy.find('.archive-button').mousedown(function () {
        targetElement.popup('hide');
        self._executeArchiveTimerClicked(timer);
      });
      popupCopy.find('.edit-button').mousedown(function () {
        targetElement.popup('hide');
        self._openInputModal(timer, true);
      });
      return popupCopy[0];
    },
    _executeArchiveTimerClicked: function _executeArchiveTimerClicked(timerToArchive) {
      var self = this;
      var i18n = this.get('i18n');
      this.set('modalMessage', i18n.t('messages.archiveTimerConfirmation'));
      Ember.$('.timeline-confirmation-modal.ui.modal').modal({
        closable: true,
        onApprove: function onApprove() {
          Ember.$('.full-calendar').fullCalendar('removeEvents', timerToArchive.id);
          self._updateTimerInStore(timerToArchive, true);
          self.updateTotalTime();
          _analytics.default.sendTimerArchivedEvent(self.get('metrics'));
          self.get('syncService').sync();
        }
      }).modal('show');
    },
    _openInputModal: function _openInputModal(fcTimerObject, isUpdate) {
      var self = this;
      var i18n = this.get('i18n');
      var end = void 0;
      this.setProperties({
        'modalInput.projectName': fcTimerObject.title,
        'modalInput.projectId': fcTimerObject.projectId,
        'modalInput.startDate': new Date(fcTimerObject.start),
        'modalInput.startTime': fcTimerObject.start.format('HH:mm'),
        'inputParameters.maxDate': new Date()
      });
      if (isUpdate) {
        end = fcTimerObject.end;
        Ember.$('.search.selection.timeline-projects-dropdown').dropdown('set text', fcTimerObject.title);
        this.set('createUpdateButtonText', i18n.t('strings.timeline.buttons.update'));
        Ember.$('.timeline-projects-dropdown').addClass('disabled');
        Ember.$('.timeline-create-timer .approve.button').removeClass('disabled');
      } else {
        fcTimerObject.isRunning = (0, _moment.default)().isSame(fcTimerObject.end, 'minutes');
        end = fcTimerObject.end;
        this.setProperties({
          'modalInput.projectId': null,
          'createUpdateButtonText': i18n.t('strings.timeline.buttons.create')
        });
        Ember.$('.timeline-projects-dropdown').removeClass('disabled');
        Ember.$('.timeline-create-timer .approve.button').addClass('disabled');
      }
      if (fcTimerObject.isRunning) {
        this.setProperties({
          'modalInput.endDate': null,
          'modalInput.endTime': null
        });
      } else {
        this.setProperties({
          'modalInput.endDate': new Date(end),
          'modalInput.endTime': end.format('HH:mm')
        });
      }
      Ember.$('.timeline-create-timer.ui.modal').modal({
        autofocus: false,
        onApprove: function onApprove() {
          var modalInput = self.get('modalInput');
          var startDateTime = Ember.$.fullCalendar.moment(modalInput.startDate);
          if (!startDateTime.isValid()) {
            _logger.default.error('A timer must have a valid start date.');
            return false; // keep the modal open
          }
          startDateTime.set('hour', modalInput.startTime.split(':')[0]);
          startDateTime.set('minute', modalInput.startTime.split(':')[1]);
          fcTimerObject.start = startDateTime;
          var endDateTime = Ember.$.fullCalendar.moment(modalInput.endDate);
          if (endDateTime.isValid()) {
            endDateTime.set('hour', modalInput.endTime.split(':')[0]);
            endDateTime.set('minute', modalInput.endTime.split(':')[1]);
            fcTimerObject.isRunning = false;
          } else {
            fcTimerObject.isRunning = true;
          }
          fcTimerObject.end = endDateTime;
          fcTimerObject.title = modalInput.projectName;
          if (isUpdate) {
            self._executeUpdateTimerEvent(fcTimerObject, function () {
              // do nothing
            });
            _analytics.default.sendTimerEditedEventViaModal(self.get('metrics'));
          } else {
            var selectedProjectId = self.get('modalInput.projectId');
            if (!selectedProjectId) {
              _logger.default.error('A timer must have a project associated with it.');
              return false; // keep the modal open
            }
            var project = self.get('store').peekRecord('project', selectedProjectId);
            self._executeCreateTimerEvent(fcTimerObject, project);
            _analytics.default.sendTimerCreatedEvent(self.get('metrics'));
          }
        },
        onHide: function onHide() {
          Ember.$('.full-calendar').fullCalendar('unselect');
        },
        onHidden: function onHidden() {
          self.resetModalParameters();
          Ember.$('.search.selection.timeline-projects-dropdown').dropdown('restore placeholder text');
          Ember.$('.search.selection.timeline-projects-dropdown .search').val('');
        }
      }).modal('show');
    },
    resetModalParameters: function resetModalParameters() {
      this.setProperties({
        'inputParameters.maxDate': new Date(),
        'modalInput.projectName': null,
        'modalInput.projectId': null,
        'modalInput.startDate': null,
        'modalInput.endDate': null,
        'modalInput.startTime': null,
        'modalInput.endTime': null,
        'modalInput.startDateTime': null,
        'modalInput.endDateTime': null
      });
    }
  });
});