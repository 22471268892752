define('timetracker/components/tt-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['reports-table'],

    tableData: null,

    scrollTimeOutId: null,
    columnHideTimeOutId: null,

    headerAlignDelayMS: 50,
    scrollTimeOutMS: 100,
    columnHideTimeoutMS: 10,

    // These values are calculate by trial and error
    defaultTableHeight: 400,
    defaultMaxWidth: 1000,
    maxWidthWithThreeColumns: 700,
    maxWidthWithTwoColumns: 340,

    defaultNoOfRowsToAdd: 100,

    tableHeader: [], // currently hardcoded, will come from parameters


    columnVisibilityObserver: Ember.observer('expandedSecondLevelCount', 'expandedFirstLevelCount', function () {
      var columnHideTimeoutMS = this.get('columnHideTimeoutMS');
      var columnHideTimeOutId = this.get('columnHideTimeOutId');
      Ember.run.cancel(columnHideTimeOutId);
      columnHideTimeOutId = Ember.run.later(this, function () {
        var table = Ember.$('#reports-table').DataTable();
        var tableRef = Ember.$('#reports-table_wrapper');

        var _getProperties = this.getProperties('expandedSecondLevelCount', 'expandedFirstLevelCount'),
            expandedSecondLevelCount = _getProperties.expandedSecondLevelCount,
            expandedFirstLevelCount = _getProperties.expandedFirstLevelCount;

        if (expandedFirstLevelCount === 0) {
          table.column(1).visible(false);
          tableRef.css('maxWidth', this.get('maxWidthWithTwoColumns'));
        } else {
          table.column(1).visible(true);
        }
        if (expandedSecondLevelCount === 0) {
          table.column(2).visible(false); // eslint-disable-line no-magic-numbers
          tableRef.css('maxWidth', this.get('maxWidthWithThreeColumns'));
        } else {
          table.column(2).visible(true); // eslint-disable-line no-magic-numbers
          tableRef.css('maxWidth', this.get('defaultMaxWidth'));
        }
        this.alignHeaders();
      }, columnHideTimeoutMS);
      this.set('columnHideTimeOutId', columnHideTimeOutId);
    }),

    setInitialTableParameters: function setInitialTableParameters() {
      this.setProperties({
        hideSecondLevelIsChecked: false,
        hideThirdLevelIsChecked: false,

        // these will allow handling visibility of columns
        expandedSecondLevelCount: 0,
        expandedFirstLevelCount: 0,

        collapsedFirstLevelRows: [],
        collapsedSecondLevelRows: [],
        expandedFirstLevelRows: [],
        expandedSecondLevelRows: [],

        // these will keep track of expanded rows when new rows are added and `DataTable` redraws
        lastExpandedFirstLevelRows: [],
        lastExpandedSecondLevelRows: [],
        lastScrollIndex: null,

        lastIndexForFirstLevelRows: 0,
        lastIndexForSecondLevelRows: 0,
        lastIndexForThirdLevelRows: 0
      });
      Ember.$('#toggle-level-three').prop('disabled', false);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (!Ember.$.fn.dataTable.isDataTable('#reports-table')) {
        var table = document.createElement('table');
        table.setAttribute('id', 'reports-table');
        Ember.$('.tt-table-container').append(table);
        this.setInitialTableParameters();
        this.initializeTable();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (Ember.$.fn.dataTable.isDataTable('#reports-table')) {
        var table = Ember.$('#reports-table').DataTable();
        table.clear().destroy(true); // eslint-disable-line new-cap
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.get('scrollTimeOutId'));
    },
    initializeTable: function initializeTable() {
      var tableHeight = this.getTableHeight();
      var columns = [];
      var defaultWidthForColumns = '100px';
      this.get('tableHeader').forEach(function (item) {
        return columns.push({ title: item, width: defaultWidthForColumns });
      });
      var self = this;
      Ember.$('#reports-table').DataTable({
        data: this.get('tableData').slice(0, this.get('defaultNoOfRowsToAdd')),
        ordering: false,
        searching: false,
        info: false,
        scrollY: tableHeight,
        paging: false,
        drawCallback: function drawCallback() {
          self.makeCollapsibleRows();
          self.handleRowVisibility();
          self.alignHeaders();
        },
        createdRow: function createdRow(rowNode, rowData) {
          var rowLevel = self.getRowLevel(rowData);
          var rowClass = self.getClassStringForNodeLevel(rowLevel);
          Ember.$(rowNode).addClass(rowClass);
        },
        columns: columns,
        columnDefs: [{
          targets: '_all',
          createdCell: function createdCell(cellNode, cellData, rowData, rowIndex, colIndex) {
            if (!cellData) {
              return;
            }
            var colLevel = colIndex + 1;
            if (colLevel === 1 || colLevel === 2) {
              // eslint-disable-line no-magic-numbers
              Ember.$(cellNode).prepend('<i class="caret right icon"></i> ');
            }
            var cellClass = self.getClassStringForNodeLevel(colLevel);
            Ember.$(cellNode).addClass(cellClass).prop('title', cellData);
          }
        }]
      });
      this.sendAction('tableLoadComplete');
      this.set('lastScrollIndex', this.get('defaultNoOfRowsToAdd'));
      this.handleRowClick();
      this.alignHeaders();
      this.initializeScrolling();
    },
    initializeScrolling: function initializeScrolling() {
      var scrollTimeOutMS = this.get('scrollTimeOutMS');
      var scrollTimeOutId = this.get('scrollTimeOutId');
      var dataTableScrollBody = Ember.$('.dataTables_scrollBody');
      var scrollAreaHeight = dataTableScrollBody.height();
      var totalScrollableHeight = Math.floor(dataTableScrollBody[0].scrollHeight - scrollAreaHeight);
      var self = this;
      Ember.$('.dataTables_scrollBody').scroll(function () {
        Ember.run.cancel(scrollTimeOutId);
        // The scroll event is getting triggered multiple times, guess that's how jQuery scroll works
        // The scrolling is handled in 100MS, so that we are assured we handle the scrol event only once
        scrollTimeOutId = Ember.run.later(self, function () {
          var currentTop = Math.ceil(dataTableScrollBody.scrollTop());
          if (currentTop === totalScrollableHeight) {
            this.addRowsToTable(this.get('defaultNoOfRowsToAdd'));
          }
        }, scrollTimeOutMS);
        self.set('scrollTimeOutId', scrollTimeOutId);
      });
    },
    addRowsToTable: function addRowsToTable(noOfRowsToAdd) {
      var table = Ember.$('#reports-table').DataTable();
      var lastIndex = this.get('lastScrollIndex');
      var nextLastIndex = lastIndex + noOfRowsToAdd;
      var rowsToAdd = this.get('tableData').slice(lastIndex, nextLastIndex);
      if (rowsToAdd.length > 0) {
        table.rows.add(rowsToAdd).draw();
        this.set('lastScrollIndex', nextLastIndex);
      }
    },
    getTableHeight: function getTableHeight() {
      var defaultHeight = this.get('defaultTableHeight');
      var topMargin = 250;
      var viewHeight = Ember.$(window).height() - topMargin;
      return viewHeight > defaultHeight ? viewHeight : defaultHeight;
    },
    getRowLevel: function getRowLevel(row) {
      var index = 0;
      var rowLength = row.length;
      do {
        if (row[index]) {
          break;
        }
        index += 1;
      } while (index < rowLength);
      return index + 1;
    },
    getClassStringForNodeLevel: function getClassStringForNodeLevel(level) {
      switch (level) {
        case 1:
          return 'level-one';
        case 2:
          return 'level-two'; // eslint-disable-line no-magic-numbers
        case 3:
          return 'level-three'; // eslint-disable-line no-magic-numbers
        default:
          return 'duration';
      }
    },
    getChildRowIndicesForNode: function getChildRowIndicesForNode(childIndices, currentParentNode, nextParentNode) {
      var childIndicesOfCurrentNode = [];
      while (childIndices[0] && nextParentNode > childIndices[0]) {
        if (childIndices[0] < currentParentNode) {
          childIndices.shift();
        } else {
          childIndicesOfCurrentNode.push(childIndices.shift());
        }
      }
      return childIndicesOfCurrentNode;
    },
    appendChildRows: function appendChildRows(parentIndices, childIndices, table, lastIndex) {
      for (var i = 0, l = parentIndices.length; i < l; i++) {
        var currentElement = parentIndices[i];
        var nextElement = parentIndices[i + 1] || lastIndex;
        var tableNode = table.row(currentElement);
        var childrenNodes = [];
        var childrenNodeIndices = [];
        childrenNodeIndices = this.getChildRowIndicesForNode(childIndices, currentElement, nextElement);
        childrenNodes = table.rows(childrenNodeIndices).nodes().to$();
        var nodesToAdd = void 0;
        if (tableNode.child()) {
          // if node alrady has child nodes, append new ones
          nodesToAdd = Ember.$.merge(tableNode.child(), childrenNodes);
        } else {
          nodesToAdd = childrenNodes;
        }
        tableNode.child(nodesToAdd);
        tableNode.child.show();
      }
    },
    alignHeaders: function alignHeaders() {
      var headerAlignDelayMS = this.get('headerAlignDelayMS');
      Ember.run.later(this, function () {
        Ember.$('#reports-table').DataTable().columns.adjust();
      }, headerAlignDelayMS);
    },
    makeCollapsibleRows: function makeCollapsibleRows() {
      var table = Ember.$('#reports-table').DataTable();
      var pageEnd = table.page.info().end;
      var levelOneIndices = [];
      var levelTwoIndices = [];
      var levelThreeIndices = [];
      var lastIndexForFirstLevelRows = this.get('lastIndexForFirstLevelRows');
      var lastIndexForSecondLevelRows = this.get('lastIndexForSecondLevelRows');
      var lastIndexForThirdLevelRows = this.get('lastIndexForThirdLevelRows');

      // keep  track of expanded rows when new ones are added
      this.set('lastExpandedSecondLevelRows', this.get('expandedSecondLevelRows').slice(0));
      this.set('lastExpandedFirstLevelRows', this.get('expandedFirstLevelRows').slice(0));

      table.rows('.level-one').indexes().each(function (index) {
        if (index >= lastIndexForFirstLevelRows) {
          levelOneIndices.push(index);
        }
      });
      this.set('lastIndexForFirstLevelRows', levelOneIndices[levelOneIndices.length - 1]);

      table.rows('.level-two').indexes().each(function (index) {
        if (index >= lastIndexForSecondLevelRows) {
          levelTwoIndices.push(index);
        }
      });
      this.set('lastIndexForSecondLevelRows', levelTwoIndices[levelTwoIndices.length - 1]);

      table.rows('.level-three').indexes().each(function (index) {
        if (index > lastIndexForThirdLevelRows) {
          levelThreeIndices.push(index);
        }
      });
      this.set('lastIndexForThirdLevelRows', levelThreeIndices[levelThreeIndices.length - 1]);

      this.appendChildRows(levelTwoIndices.slice(0), levelThreeIndices.slice(0), table, pageEnd);
      if (lastIndexForSecondLevelRows > 0) {
        levelTwoIndices.shift();
      }
      this.appendChildRows(levelOneIndices.slice(0), levelTwoIndices.slice(0), table, pageEnd);
      this.showChildRows(levelTwoIndices, 2); // eslint-disable-line no-magic-numbers
      this.showChildRows(levelOneIndices, 1);
    },
    handleRowVisibility: function handleRowVisibility() {
      var collapsedFirstLevelRows = this.get('collapsedFirstLevelRows');
      var lastExpandedFirstLevelRows = this.get('lastExpandedFirstLevelRows');
      var collapsedSecondLevelRows = this.get('collapsedSecondLevelRows');
      var lastExpandedSecondLevelRows = this.get('lastExpandedSecondLevelRows');
      if (this.get('hideThirdLevelIsChecked')) {
        this.hideChildRows('.level-two', 2); // eslint-disable-line no-magic-numbers
      } else if (collapsedSecondLevelRows.length > 0) {
        this.hideChildRows(collapsedSecondLevelRows, 2); // eslint-disable-line no-magic-numbers
      }
      if (this.get('hideSecondLevelIsChecked')) {
        this.hideChildRows('.level-one', 1);
      } else if (collapsedFirstLevelRows.length > 0) {
        this.hideChildRows(collapsedFirstLevelRows, 1);
      }
      if (lastExpandedFirstLevelRows.length > 0) {
        this.showChildRows(lastExpandedFirstLevelRows, 1);
      }
      if (lastExpandedSecondLevelRows.length > 0) {
        this.showChildRows(lastExpandedSecondLevelRows, 2); // eslint-disable-line no-magic-numbers
      }
      this.set('lastExpandedSecondLevelRows', []);
      this.set('lastExpandedFirstLevelRows', []);
    },
    getLevelArrays: function getLevelArrays(level) {
      var collapsedRows = void 0;
      var expandedRows = void 0;
      if (level === 1) {
        collapsedRows = this.get('collapsedFirstLevelRows');
        expandedRows = this.get('expandedFirstLevelRows');
      } else if (level === 2) {
        // eslint-disable-line no-magic-numbers
        collapsedRows = this.get('collapsedSecondLevelRows');
        expandedRows = this.get('expandedSecondLevelRows');
      }
      return { collapsedRows: collapsedRows, expandedRows: expandedRows };
    },
    setLevelArrays: function setLevelArrays(level, collapsedRows, expandedRows) {
      if (level === 1) {
        this.setProperties({ collapsedFirstLevelRows: collapsedRows, expandedFirstLevelRows: expandedRows, expandedFirstLevelCount: expandedRows.length });
      } else {
        this.setProperties({ collapsedSecondLevelRows: collapsedRows, expandedSecondLevelRows: expandedRows, expandedSecondLevelCount: expandedRows.length });
      }
    },
    addToCollapsed: function addToCollapsed(item, level) {
      var _getLevelArrays = this.getLevelArrays(level),
          collapsedRows = _getLevelArrays.collapsedRows,
          expandedRows = _getLevelArrays.expandedRows;

      if (collapsedRows.indexOf(item) === -1) {
        // eslint-disable-line no-magic-numbers
        collapsedRows.push(item);
      }
      var indexInExpanded = expandedRows.indexOf(item);
      if (indexInExpanded >= 0) {
        expandedRows.splice(indexInExpanded, 1);
      }
      this.setLevelArrays(level, collapsedRows, expandedRows);
    },
    addToExpanded: function addToExpanded(item, level) {
      var _getLevelArrays2 = this.getLevelArrays(level),
          collapsedRows = _getLevelArrays2.collapsedRows,
          expandedRows = _getLevelArrays2.expandedRows;

      if (expandedRows.indexOf(item) === -1) {
        // eslint-disable-line no-magic-numbers
        expandedRows.push(item);
      }
      var indexInCollapsed = collapsedRows.indexOf(item);
      if (indexInCollapsed >= 0) {
        collapsedRows.splice(indexInCollapsed, 1);
      }
      this.setLevelArrays(level, collapsedRows, expandedRows);
    },
    hideChildRows: function hideChildRows(parentRows, rowLevel) {
      var self = this;
      var table = Ember.$('#reports-table').DataTable();
      var rowsToCollapse = table.rows(parentRows);
      rowsToCollapse.every(function () {
        var row = table.row(this);
        table.row(this).child.hide();
        self.showCollapsedArrow(this.node());
        self.addToCollapsed(row.index(), rowLevel);
        return true;
      });
    },
    showChildRows: function showChildRows(parentRows, rowLevel) {
      var self = this;
      var table = Ember.$('#reports-table').DataTable();
      var rowsToExpand = table.rows(parentRows);
      rowsToExpand.every(function () {
        var row = table.row(this);
        table.row(this).child.show();
        self.showExpandedArrow(this.node());
        self.addToExpanded(row.index(), rowLevel);
        return true;
      });
    },
    handleRowClick: function handleRowClick() {
      var self = this;
      Ember.$('#reports-table tbody').on('click', 'tr', function () {
        var table = Ember.$('#reports-table').DataTable();
        var row = table.row(this);
        if (!row.child()) {
          return;
        }
        var rowsHidden = 0;
        var defaultMinHiddenRows = 10;
        var didClickLevelOneRow = Ember.$(this).hasClass('level-one');
        var child = row.child;
        var parentNodeLevel = didClickLevelOneRow ? 1 : 2; // eslint-disable-line no-magic-numbers
        if (child.isShown()) {
          if (parentNodeLevel === 1) {
            var nestedNodes = table.row(this).child();
            nestedNodes.each(function () {
              var childRow = table.row(this);
              rowsHidden += childRow.child().length;
            });
            self.hideChildRows(nestedNodes, 2); // eslint-disable-line no-magic-numbers
          }
          self.hideChildRows(row, parentNodeLevel);
          rowsHidden += row.child().length;
        } else {
          self.showChildRows(row, parentNodeLevel);
        }
        if (rowsHidden > defaultMinHiddenRows) {
          self.addRowsToTable(rowsHidden);
        }
        self.alignHeaders();
      });
    },
    showExpandedArrow: function showExpandedArrow(element) {
      Ember.$(element).find('i').removeClass('right').addClass('down');
    },
    showCollapsedArrow: function showCollapsedArrow(element) {
      Ember.$(element).find('i').removeClass('down').addClass('right');
    },


    actions: {
      didClickOnSeeFirstLevelOnly: function didClickOnSeeFirstLevelOnly() {
        var secondLevelRows = Ember.$('tr .level-two');
        var firstLevelRows = Ember.$('tr .level-one');
        if (!this.get('hideSecondLevelIsChecked')) {
          this.set('hideThirdLevelIsChecked', true);
          this.set('hideSecondLevelIsChecked', true);
          this.hideChildRows(secondLevelRows, 2); // eslint-disable-line no-magic-numbers
          this.hideChildRows(firstLevelRows, 1);
          Ember.$('#toggle-level-three').prop('disabled', true);
        } else {
          this.set('hideSecondLevelIsChecked', false);
          this.showChildRows(firstLevelRows, 1);
          Ember.$('#toggle-level-three').prop('disabled', false);
        }
        this.alignHeaders();
      },
      didClickOnSeeFirstAndSecondLevelOnly: function didClickOnSeeFirstAndSecondLevelOnly() {
        var secondLevelRows = Ember.$('tr .level-two');
        if (!this.get('hideThirdLevelIsChecked')) {
          this.set('hideThirdLevelIsChecked', true);
          this.hideChildRows(secondLevelRows, 2); // eslint-disable-line no-magic-numbers
        } else {
          this.set('hideThirdLevelIsChecked', false);
          this.showChildRows(secondLevelRows, 2); // eslint-disable-line no-magic-numbers
        }
        this.alignHeaders();
      }
    }
  });
});