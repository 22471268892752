define('timetracker/utils/analytics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var analytics = {
    sendRouteEnteredEvent: function sendRouteEnteredEvent(route, emberMetrics) {
      var routeName = route.get('routeName');
      var currentRoute = routeName.substr(routeName.lastIndexOf('.') + 1);
      var path = '/' + currentRoute;
      emberMetrics.trackPage({ page: path });
    },
    sendTimerStartEvent: function sendTimerStartEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Timer',
        eventAction: 'Start',
        eventLabel: 'Timer started from timers screen'
      });
    },
    sendTimerStopEvent: function sendTimerStopEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Timer',
        eventAction: 'Stop',
        eventLabel: 'Timer stopped from timers screen'
      });
    },
    sendTimerEditedEventViaDragging: function sendTimerEditedEventViaDragging(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Timer',
        eventAction: 'Edited',
        eventLabel: 'Timer modified by dragging in timeline view'
      });
    },
    sendTimerEditedEventViaModal: function sendTimerEditedEventViaModal(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Timer',
        eventAction: 'Edited',
        eventLabel: 'Timer modified by modal input in timeline view'
      });
    },
    sendTimerCreatedEvent: function sendTimerCreatedEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Timer',
        eventAction: 'Created',
        eventLabel: 'Timer created from timeline view'
      });
    },
    sendTimerArchivedEvent: function sendTimerArchivedEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Timer',
        eventAction: 'Archived',
        eventLabel: 'Timer archived from popup in timeline view'
      });
    },
    sendSuccessfulLoginEvent: function sendSuccessfulLoginEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Login',
        eventAction: 'Success',
        eventLabel: 'User successfully logged in'
      });
    },
    sendFailedLoginEvent: function sendFailedLoginEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Login',
        eventAction: 'Fail',
        eventLabel: 'User login failed'
      });
    },
    sendLogOutEvent: function sendLogOutEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Logout',
        eventAction: 'Logout',
        eventLabel: 'User logged out by clicking sign out button'
      });
    },
    sendReportRequestEvent: function sendReportRequestEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Reports',
        eventAction: 'Fetch report',
        eventLabel: 'Report was requested to view in web browser'
      });
    },
    sendExportReportEvent: function sendExportReportEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Reports',
        eventAction: 'Export report',
        eventLabel: 'Report export request was made'
      });
    },
    sendProjectCreatedEvent: function sendProjectCreatedEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Project management',
        eventAction: 'Project created',
        eventLabel: 'New project created from admin page'
      });
    },
    sendProjectModifiedEvent: function sendProjectModifiedEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'Project management',
        eventAction: 'Project modified',
        eventLabel: 'Existing project modified from admin page'
      });
    },
    sendUserCreatedEvent: function sendUserCreatedEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'User management',
        eventAction: 'User created',
        eventLabel: 'New user created from admin page'
      });
    },
    sendUserModifiedEvent: function sendUserModifiedEvent(emberMetrics) {
      emberMetrics.trackEvent({
        eventCategory: 'User management',
        eventAction: 'User modified',
        eventLabel: 'Existing User modified from admin page'
      });
    }
  };

  exports.default = analytics;
});