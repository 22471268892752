define('timetracker/router', ['exports', 'timetracker/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  // eslint-disable-next-line array-callback-return
  Router.map(function () {
    this.route('login');
    this.route('authenticated', { path: '/' }, function () {
      this.route('manage', function () {
        this.route('profile');
        this.route('administration');
      });
      this.route('features', { path: '/' }, function () {
        this.route('reports');
        this.route('timers');
        this.route('timeline');
      });
    });
    this.route('not-found', { path: '/*' });
    this.route('unauthorized');
  });

  exports.default = Router;
});