define('timetracker/utils/string-util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var stringUtil = {
    condenseSpace: function condenseSpace(string) {
      return string.trim().replace(/\s\s+/g, ' '); // \s matches all whitespaces, including new line and tab
    },
    generatePasswordString: function generatePasswordString() {
      // https://stackoverflow.com/a/9719815/6293468
      var base = 36;
      var noOfChars = -8; // last 8 characters
      return Math.random() // Generate random number, eg: 0.123456
      .toString(base) // Convert  to base-36 : "0.4fzyo82mvyr"
      .slice(noOfChars);
    },
    searchList: function searchList(list, expression) {
      var searchExpression = new RegExp(this.escapeRegExp(expression), 'i');
      var filteredList = list.filter(function (item) {
        return item.get('name').search(searchExpression) >= 0;
      });
      return filteredList.sortBy('name');
    },
    escapeRegExp: function escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
  };

  exports.default = stringUtil;
});