define('timetracker/utils/network-error-event-broadcast', ['exports', 'timetracker/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var networkErrorEventBroadcast = {
    broadcastCustomEvent: function broadcastCustomEvent(failedReason, url) {
      var adapterErrorEvent = new CustomEvent(_constants.default.EVENTS.ADAPTER_REQUEST_ERROR);
      adapterErrorEvent.reason = failedReason;
      dispatchEvent(adapterErrorEvent);
      var requestId = this._parseRequestId(url);
      return this._makeErrorResponse(failedReason, requestId);
    },
    _makeErrorResponse: function _makeErrorResponse(reason, requestId) {
      return {
        errors: [{
          id: requestId,
          status: '' + reason.status,
          code: '' + reason.status,
          detail: '' + reason.statusText
        }]
      };
    },
    _parseRequestId: function _parseRequestId(url) {
      // https://stackoverflow.com/a/901144/6293468
      var regex = new RegExp('[?&]' + _constants.default.REQUEST_ID + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(url);
      if (!results) {
        return null;
      }
      if (!results[2]) {
        // eslint-disable-line no-magic-numbers
        return '';
      }
      return decodeURIComponent(results[2].replace(/\+/g, ' ')); // eslint-disable-line no-magic-numbers
    }
  };
  exports.default = networkErrorEventBroadcast;
});