define('timetracker/components/login-form', ['exports', 'timetracker/constants', 'timetracker/utils/logger', 'timetracker/utils/string-util', 'timetracker/utils/analytics'], function (exports, _constants, _logger, _stringUtil, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    loginErrorMessage: null,
    modalErrorMessage: null,

    teamName: null,
    emailId: null,
    password: null,

    i18n: Ember.inject.service(),
    metrics: Ember.inject.service(),

    inputsObserver: Ember.observer('teamName', 'emailId', 'password', function () {
      var _getProperties = this.getProperties('teamName', 'emailId', 'password'),
          teamName = _getProperties.teamName,
          emailId = _getProperties.emailId,
          password = _getProperties.password;

      if (teamName && emailId && password) {
        this._enableLogin();
        this._clearLoginErrorMessage();
      } else if (!teamName || !emailId || !password) {
        this._disableLogin();
      }
    }),

    actions: {

      didClickOnLogin: function didClickOnLogin() {
        this._clearLoginErrorMessage();
        var condensedTeamName = _stringUtil.default.condenseSpace(this.get('teamName'));
        var condensedEmailId = _stringUtil.default.condenseSpace(this.get('emailId'));
        var password = this.get('password');
        this._login({ teamName: condensedTeamName, emailId: condensedEmailId, password: password });
      },

      openForgotPasswordModal: function openForgotPasswordModal() {
        var self = this;
        Ember.$('.forgot-password-modal').modal({
          onApprove: function onApprove() {
            var teamName = self.get('teamName');
            var emailId = self.get('emailId');
            Ember.$('.forgot-password-modal input').removeClass('error');
            if (!teamName || !emailId) {
              if (!teamName) {
                self.set('teamName', '');
                Ember.$('.forgot-password-modal input.team').addClass('error');
              }
              if (!emailId) {
                self.set('emailId', '');
                Ember.$('.forgot-password-modal input.email').addClass('error');
              }
              return false;
            }
            var condensedTeamName = _stringUtil.default.condenseSpace(teamName);
            var condensedEmailId = _stringUtil.default.condenseSpace(emailId);
            self.get('forgotPassword')(condensedEmailId, condensedTeamName).then(function () {
              Ember.$('.forgot-password-modal').modal('hide');
            }).catch(function (reason) {
              var message = self._makeErrorMessageForFailedRequest(reason);
              self.setModalErrorMessage(message);
            });
            return false;
          },
          onHide: function onHide() {
            self._disableLogin();
            self.set('modalErrorMessage', null);
          }
        }).modal('show');
      }
    },

    _clearLoginErrorMessage: function _clearLoginErrorMessage() {
      this.set('loginErrorMessage', null);
    },

    _setLoginErrorMessage: function _setLoginErrorMessage(message) {
      this.set('loginErrorMessage', message);
    },

    setModalErrorMessage: function setModalErrorMessage(message) {
      this.set('modalErrorMessage', message);
    },

    _login: function _login(loginCredentials) {
      var _this = this;

      // Disable 'Sign In' button until the server responds and show spinner
      this._showSpinner();
      // call login action in the containing route
      // handle errors here and let route handle the success
      this.get('login')(loginCredentials).then(function () {
        _analytics.default.sendSuccessfulLoginEvent(_this.get('metrics'));
      }).catch(function (reason) {
        // Set error message on login failure
        var message = _this._makeErrorMessageForFailedRequest(reason);
        _this._setLoginErrorMessage(message);
        _this._hideSpinner();
        _analytics.default.sendFailedLoginEvent(_this.get('metrics'));
      });
    },

    _makeErrorMessageForFailedRequest: function _makeErrorMessageForFailedRequest(failureReason) {
      var statusCode = failureReason.status;
      var errorMessage = void 0;
      var emptyErrorCode = '';
      var errorCode = failureReason.responseJSON ? failureReason.responseJSON.errorCode : emptyErrorCode;
      var i18n = this.get('i18n');
      var responseCode = _constants.default.RESPONSE_CODES;
      if (statusCode === responseCode.UNAUTHORIZED_ERROR) {
        switch (errorCode) {
          case _constants.default.RESPONSE_401_ERRORS.INVALID_CREDENTIALS:
          case emptyErrorCode:
            errorMessage = i18n.t('messages.unauthorizedLogin');
            break;
          case _constants.default.RESPONSE_401_ERRORS.USER_DISABLED:
            errorMessage = i18n.t('messages.userIsDisabled');
            break;
          default:
            _logger.default.error('Unknown error code: ' + errorCode);
        }
      } else if (statusCode === responseCode.BAD_REQUEST) {
        switch (errorCode) {
          case _constants.default.RESPONSE_400_ERRORS.TOO_MANY_ATTEMPTS:
            errorMessage = i18n.t('messages.tooManyAttemptsForgotPassword');
            break;
          case _constants.default.RESPONSE_400_ERRORS.USER_DOES_NOT_EXIST:
            errorMessage = i18n.t('messages.userDoesNotExistForgotPasssword');
            break;
          case _constants.default.RESPONSE_400_ERRORS.EMAIL_ID_NOT_VERIFIED:
            this._showEmailNotVerifiedModal();
            break;
          case emptyErrorCode:
            errorMessage = i18n.t('messages.genericError');
            break;
          default:
            _logger.default.error('Unknown error code: ' + errorCode);
        }
      } else if (statusCode === responseCode.SERVER_ERROR) {
        errorMessage = i18n.t('messages.genericError');
      } else if (statusCode === responseCode.SERVICE_UNAVAILABLE_502 || statusCode === responseCode.SERVICE_UNAVAILABLE_503) {
        errorMessage = i18n.t('messages.serviceUnavailable');
      } else if (statusCode === responseCode.NETWORK_ERROR) {
        errorMessage = i18n.t('messages.networkError');
      } else {
        errorMessage = i18n.t('messages.unexpectedError');
        _logger.default.error('Unknown status code: ' + statusCode);
      }
      if (statusCode !== responseCode.UNAUTHORIZED_ERROR) {
        this._enableLogin();
      }
      return errorMessage;
    },

    _showEmailNotVerifiedModal: function _showEmailNotVerifiedModal() {
      var self = this;
      Ember.$('.modal.ui.email-not-verified').modal({
        closable: false,
        onApprove: function onApprove() {
          var email = self.get('emailId');
          var team = self.get('teamName');
          self.get('resendVerifyEmail')(email, team);
        }
      }).modal('show');
    },


    _showSpinner: function _showSpinner() {
      this._disableLogin();
      Ember.$('.login-button-spinner').css('display', 'block');
      Ember.$('.login-button-text').css('display', 'none');
    },

    _hideSpinner: function _hideSpinner() {
      // Show 'Sign In' and hide spinner
      Ember.$('.login-button-spinner').css('display', 'none');
      Ember.$('.login-button-text').css('display', 'inline');
    },

    _enableLogin: function _enableLogin() {
      Ember.$('.login-button').prop('disabled', false);
    },
    _disableLogin: function _disableLogin() {
      Ember.$('.login-button').prop('disabled', true);
    }
  });
});