define('timetracker/components/edit-profile', ['exports', 'timetracker/utils/network-request', 'timetracker/constants', 'timetracker/utils/logger', 'timetracker/utils/string-util'], function (exports, _networkRequest, _constants, _logger, _stringUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    oldPassword: null,
    newPassword: null,
    confirmedPassword: null,
    userName: null,
    userEmail: null,

    enablePasswordUpdate: Ember.observer('oldPassword', 'newPassword', 'confirmedPassword', function () {
      var _getProperties = this.getProperties('oldPassword', 'newPassword', 'confirmedPassword'),
          oldPassword = _getProperties.oldPassword,
          newPassword = _getProperties.newPassword,
          confirmedPassword = _getProperties.confirmedPassword;

      var buttonRef = Ember.$('.update-password-button');
      var confirmPasswordInputRef = Ember.$('.confirmPasswordInput');
      var oldPasswordInputRef = Ember.$('.oldPasswordInput');
      var oldPasswordMessageRef = Ember.$('.wrong-old-password-message');
      var confirmPasswordMessageRef = Ember.$('.confirm-password-error-message');
      if (oldPassword && newPassword && confirmedPassword) {
        this.toggleInputErrorState(oldPasswordInputRef, false, oldPasswordMessageRef);
        Ember.$('.message.change-password-success').addClass('hidden');
        if (newPassword === confirmedPassword) {
          this.toggleInputErrorState(confirmPasswordInputRef, false, confirmPasswordMessageRef);
          return this._enableButton(buttonRef);
        }
        this.toggleInputErrorState(confirmPasswordInputRef, true, confirmPasswordMessageRef);
        return this._disableButton(buttonRef);
      }
      this.toggleInputErrorState(confirmPasswordInputRef, false, confirmPasswordMessageRef);
      this._disableButton(buttonRef);
    }),

    enableNameUpdateButton: Ember.observer('userName', function () {
      var name = this.get('userName');
      var buttonRef = Ember.$('.update-name-button');
      Ember.$('.message.change-name-success').addClass('hidden');
      Ember.$('.message.change-name-failure').addClass('hidden');
      if (name) {
        return this._enableButton(buttonRef);
      }
      this._disableButton(buttonRef);
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var user = this.get('user');
      this.setProperties({
        userName: user.name,
        userEmail: user.emailId
      });
    },


    actions: {
      updatePassword: function updatePassword() {
        var _this = this;

        var buttonRef = Ember.$('.update-password-button');
        var path = 'changePassword';
        var data = {
          oldPassword: this.get('oldPassword'),
          newPassword: this.get('newPassword')
        };
        this._showSpinner(buttonRef);
        this._disableButton(buttonRef);
        this._sendRequest(path, 'POST', data).then(function () {
          _this.setProperties({
            oldPassword: null,
            newPassword: null,
            confirmedPassword: null
          });
          Ember.$('.message.change-password-success').removeClass('hidden');
          _this._hideSpinner(buttonRef);
        }).catch(function (reason) {
          if (reason.status === _constants.default.RESPONSE_CODES.UNAUTHORIZED_ERROR) {
            _this.set('oldPassword', null);
            var oldPasswordInputRef = Ember.$('.oldPasswordInput');
            var messageRef = Ember.$('.message.wrong-old-password-message');
            _this.toggleInputErrorState(oldPasswordInputRef, true, messageRef);
            oldPasswordInputRef.focus();
            _this._hideSpinner(buttonRef);
          } else {
            _logger.default.debug('Unknown status code: ' + reason.status);
            _this._hideSpinner(buttonRef);
          }
        });
      },
      updateName: function updateName() {
        var _this2 = this;

        var buttonRef = Ember.$('.update-name-button');
        var path = 'changeName';
        var newName = this.get('userName');
        var data = {
          name: _stringUtil.default.condenseSpace(newName)
        };
        this._showSpinner(buttonRef);
        this._disableButton(buttonRef);
        this._sendRequest(path, 'POST', data).then(function () {
          Ember.$('.message.change-name-success').removeClass('hidden');
          _this2._hideSpinner(buttonRef);
          _this2.set('user.name', newName);
        }).catch(function (reason) {
          Ember.$('.message.change-name-failure').removeClass('hidden');
          _this2._hideSpinner(buttonRef);
          _logger.default.debug('Update Name failure status code: ' + reason.status);
        });
      }
    },

    _sendRequest: function _sendRequest(path, method, data) {
      var url = _networkRequest.default.buildURL(path);
      var hash = _networkRequest.default.setRequestParameters(url, method, { data: data });
      return _networkRequest.default.makeAPIRequest(hash);
    },


    _showSpinner: function _showSpinner(buttonRef) {
      buttonRef.find('.button-spinner').css('display', 'block');
      buttonRef.find('.button-text').css('display', 'none');
    },

    _hideSpinner: function _hideSpinner(buttonRef) {
      buttonRef.find('.button-spinner').css('display', 'none');
      buttonRef.find('.button-text').css('display', 'block');
    },

    _enableButton: function _enableButton(buttonRef) {
      buttonRef.prop('disabled', false);
    },
    _disableButton: function _disableButton(buttonRef) {
      buttonRef.prop('disabled', true);
    },
    toggleInputErrorState: function toggleInputErrorState(inputRef, errorState, messageRef) {
      if (errorState) {
        inputRef.addClass('input-error-state');
        messageRef.removeClass('hidden');
      } else {
        inputRef.removeClass('input-error-state');
        messageRef.addClass('hidden');
      }
    }
  });
});