define('timetracker/adapters/application', ['exports', 'ember-data', 'timetracker/config/environment', 'timetracker/utils/network-request', 'timetracker/utils/network-error-handler'], function (exports, _emberData, _environment, _networkRequest, _networkErrorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Adapter.extend({
    // url of the resource
    host: _environment.default.APP.host,
    // apiVersion in the url for the resource
    apiVersion: _environment.default.APP.apiVersion,

    buildURL: function buildURL() {
      return _networkRequest.default.buildURL.apply(_networkRequest.default, arguments);
    },


    /**
     sendAjax method is used to send ajax request to the server.
     It sends an http request, waits for the response, on successful response
     returns the data on the response
     @method sendAjax
     @param {string} url
     the url of the server
     @param {string} requestMethod
     the type of request method to be used
     @param {object} options
     if the request has optional body to be send, it is passed as data
     @return {promise}
     Returns a promise object that if succeeds resolves to payload
     */
    sendAjax: function sendAjax(url, requestMethod, options) {
      var hash = this.setRequestParameters(url, requestMethod, options);
      return _networkRequest.default.makeAPIRequest(hash).then(function (response) {
        return response;
      }, function (reason) {
        var error = new _emberData.default.AdapterError(_networkErrorHandler.default.handleErrorResponse(reason, url));
        return Ember.RSVP.Promise.reject(error);
      });
    },
    setRequestParameters: function setRequestParameters() {
      return _networkRequest.default.setRequestParameters.apply(_networkRequest.default, arguments);
    }
  });
});