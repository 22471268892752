define('timetracker/routes/authenticated/features/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    loginService: Ember.inject.service('loginService'),

    actions: {
      didTransition: function didTransition() {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var loginService = this.get('loginService');
          if (loginService.redirectionUrl) {
            this.replaceWith(loginService.redirectionUrl);
          } else {
            this.replaceWith('authenticated.features.timers');
          }
        });
      }
    }
  });
});