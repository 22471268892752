define('timetracker/components/project-administration', ['exports', 'timetracker/constants', 'ember-uuid', 'timetracker/utils/string-util', 'timetracker/utils/analytics'], function (exports, _constants, _emberUuid, _stringUtil, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    metrics: Ember.inject.service(),

    projectSearchText: '',
    userSearchText: '',
    projectList: null,
    selectedProject: null,
    projectName: '',
    projectColor: '',
    usersInProject: [],
    disabledUsersInProject: [],
    usersSearchList: [],
    projectUsersList: [],
    isEditMode: false,
    isNewProject: false,
    userToAdd: null,
    isColorPickerInitialized: false,
    updateMS: 100,
    projects: null,
    users: null,

    allUsersShowed: true,

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('projectList', Ember.copy(this.get('projects').sortBy('name'), false));
    },
    toggleAllUsers: function toggleAllUsers(shouldShow) {
      if (!this.get('isEditMode')) {
        return;
      }
      var isVisible = this.get('allUsersShowed');
      if (shouldShow === isVisible) {
        return;
      }
      var checkboxRef = Ember.$('.users-in-project-list-container .all-users');
      checkboxRef.transition('slide right');
      this.set('allUsersShowed', shouldShow);
    },


    actions: {
      searchProjects: function searchProjects() {
        this.set('projectList', _stringUtil.default.searchList(this.get('projects'), this.get('projectSearchText')));
      },
      searchUsers: function searchUsers() {
        var searchText = this.get('userSearchText');
        this.toggleAllUsers(searchText.length === 0);
        var filterList = _stringUtil.default.searchList(this.get('usersSearchList'), searchText);
        var userList = this.get('isEditMode') ? 'projectUsersList' : 'usersInProject';
        this.set(userList, filterList);
      },
      projectSelected: function projectSelected(project) {
        this.clearErrorMessages();
        this.initializeSelectedProject(project);
      },
      enableEditing: function enableEditing() {
        this.clearErrorMessages();
        var enabledUsers = this.get('users').sortBy('name').filterBy('disabled', false);
        var isNewProject = this.get('isNewProject');
        var currentUser = this.get('users').findBy('id', this.get('currentUser').userId);
        this.setProperties({
          isEditMode: true,
          projectUsersList: Ember.copy(enabledUsers, false),
          userSearchText: '',
          usersSearchList: Ember.copy(enabledUsers, false),
          usersInProject: isNewProject ? [currentUser] : this.getActiveUsersInProject(this.get('selectedProject'))
        });
        this.checkExistingUsersInProject();
        Ember.run.later(this, function () {
          this.updateProjectColorDiv();
          this.initializeColorPicker();
          this.initializeCheckboxes();
        }, this.get('updateMS'));
      },
      cancelEditing: function cancelEditing() {
        this.clearErrorMessages();
        var isNewProject = this.get('isNewProject');
        if (!isNewProject) {
          this.initializeSelectedProject(this.get('selectedProject'));
        } else {
          this.setProperties({
            selectedProject: null,
            isColorPickerInitialized: false,
            isNewProject: false
          });
        }
      },
      addProject: function addProject() {
        this.setProperties({
          isNewProject: true,
          selectedProject: {},
          projectName: '',
          projectColor: _constants.default.DEFAULT_PROJECT_COLOR,
          projectSearchText: '',
          projectList: Ember.copy(this.get('projects').sortBy('name'), false)
        });
        this.send('enableEditing');
      },
      changeColor: function changeColor(text, selectedColor) {
        this.set('projectColor', selectedColor);
        this.updateProjectColorDiv();
      },
      saveProject: function saveProject() {
        var isNewProject = this.get('isNewProject');

        var _getProperties = this.getProperties('projectName', 'projectColor', 'selectedProject'),
            projectName = _getProperties.projectName,
            projectColor = _getProperties.projectColor,
            selectedProject = _getProperties.selectedProject;

        this.clearErrorMessages();
        var condensedProjectName = projectName ? _stringUtil.default.condenseSpace(projectName) : '';
        if (!projectName || condensedProjectName.length <= 0) {
          this.set('projectName', null);
          return Ember.$('.project-name-required').removeClass('hidden');
        }
        var selectedUsers = this.get('usersSearchList').filterBy('checked', true);
        if (selectedUsers.length === 0) {
          return Ember.$('.empty-user-list').removeClass('hidden');
        }
        var doesNameAlreadyExist = this.get('projects').any(function (item) {
          if (isNewProject) {
            return item.get('name') === condensedProjectName;
          }
          return item.get('name') === condensedProjectName && selectedProject.get('projectId') !== item.id;
        });
        if (doesNameAlreadyExist) {
          return Ember.$('.project-name-exist').removeClass('hidden');
        }
        this.executeProjectSave(condensedProjectName, projectColor, selectedUsers);
      },
      archiveUnarchiveClicked: function archiveUnarchiveClicked() {
        var selectedProject = this.get('selectedProject');
        selectedProject.set('archived', !selectedProject.get('archived'));
        selectedProject.save().catch(function () {
          selectedProject.rollbackAttributes();
        });
      }
    },

    clearErrorMessages: function clearErrorMessages() {
      Ember.$('.project-name-required, .project-name-exist, .empty-user-list').addClass('hidden');
    },
    updateProjectColorDiv: function updateProjectColorDiv() {
      var color = _constants.default.PROJECT_COLORS[this.get('projectColor')];
      Ember.$('#selected-project-color').css('background-color', color);
    },
    checkExistingUsersInProject: function checkExistingUsersInProject() {
      var projectUsersList = this.get('projectUsersList');
      var usersInProject = this.get('usersInProject');
      projectUsersList.forEach(function (user) {
        user.set('checked', usersInProject.includes(user));
      });
      this.set('projectUsersList', projectUsersList);
    },
    initializeColorPicker: function initializeColorPicker() {
      if (this.get('isColorPickerInitialized')) {
        return;
      }
      Ember.run.later(this, function () {
        var colorPickerRef = Ember.$('.menu.project-color-container');
        var colors = _constants.default.PROJECT_COLORS;
        for (var key in colors) {
          var colorBox = document.createElement('span');
          var colorBoxRef = Ember.$(colorBox);
          colorBoxRef.addClass('color-rectangle item');
          colorBoxRef.css('backgroundColor', colors[key]);
          colorBoxRef.attr('data-value', key);
          colorPickerRef.append(colorBox);
        }
        this.set('isColorPickerInitialized', true);
      }, this.get('updateMS'));
    },
    getActiveUsersInProject: function getActiveUsersInProject(project) {
      var currentUserListIds = project.get('userIds');
      return this.get('users').filter(function (user) {
        return currentUserListIds.indexOf(user.id) >= 0 && user.get('disabled') === false;
      }).sortBy('name');
    },
    initializeSelectedProject: function initializeSelectedProject(project) {
      var currentUserListIds = project.get('userIds');
      var activeUsersInProject = this.getActiveUsersInProject(project);
      var disabledUsersInProject = this.get('users').filter(function (user) {
        return currentUserListIds.indexOf(user.id) >= 0 && user.get('disabled') === true;
      });
      this.setProperties({
        selectedProject: project,
        projectName: project.get('name'),
        projectColor: project.get('colorCode'),
        disabledUsersInProject: disabledUsersInProject,
        usersInProject: activeUsersInProject,
        usersSearchList: activeUsersInProject,
        userSearchText: '',
        isNewProject: false,
        isEditMode: false
      });
      Ember.run.later(this, function () {
        this.updateProjectColorDiv();
      }, this.get('updateMS'));
    },
    initializeCheckboxes: function initializeCheckboxes() {
      var selectAllCheckbox = Ember.$('.all-users .ui.checkbox');
      var userListCheckboxes = Ember.$('.users-in-project-list-content .ui.checkbox');
      var self = this;
      selectAllCheckbox.checkbox({
        onChange: function onChange() {
          var shouldSelectAll = selectAllCheckbox.checkbox('is checked');
          var users = self.get('usersSearchList').sortBy('name');
          users.setEach('checked', shouldSelectAll);
          self.setProperties({ usersSearchList: users, projectUsersList: users, userSearchText: '' });
        }
      });

      this.updateAllUserCheckbox(userListCheckboxes, selectAllCheckbox);
      userListCheckboxes.checkbox({
        onChange: function onChange() {
          self.updateAllUserCheckbox(userListCheckboxes, selectAllCheckbox);
        }
      });
    },
    updateAllUserCheckbox: function updateAllUserCheckbox(userListCheckboxes, selectAllCheckbox) {
      var isSingleUser = userListCheckboxes.length === 1;
      var checkedListBoolean = userListCheckboxes.checkbox('is checked');
      checkedListBoolean = isSingleUser ? [checkedListBoolean] : checkedListBoolean;
      var isAnyChecked = checkedListBoolean.indexOf(true);
      var isAnyUnchecked = checkedListBoolean.indexOf(false);
      if (isAnyChecked >= 0 && isAnyUnchecked < 0) {
        selectAllCheckbox.checkbox('set checked');
      } else if (isAnyUnchecked >= 0 && isAnyChecked < 0) {
        selectAllCheckbox.checkbox('set unchecked');
      } else {
        selectAllCheckbox.checkbox('set indeterminate');
      }
    },
    executeProjectSave: function executeProjectSave(projectName, projectColor, usersInProject) {
      var store = this.get('store');
      var selectedProject = this.get('selectedProject');
      var userIds = [];
      var projectId = void 0;
      var self = this;
      var disabledUsersInProject = this.get('disabledUsersInProject');
      usersInProject.forEach(function (user) {
        return userIds.push(user.id);
      });
      disabledUsersInProject.forEach(function (user) {
        return userIds.push(user.id);
      });
      if (selectedProject.id) {
        selectedProject.setProperties({
          name: projectName,
          colorCode: projectColor,
          userIds: userIds
        });
        selectedProject.save().catch(function (reason) {
          self.sendAction('handleError', reason);
          selectedProject.rollbackAttributes();
          self.set('selectedProject', null);
        });
        this.initializeSelectedProject(selectedProject);
        _analytics.default.sendProjectModifiedEvent(this.get('metrics'));
      } else {
        projectId = (0, _emberUuid.v4)();
        var newProject = {
          projectId: projectId,
          id: projectId,
          name: projectName,
          colorCode: projectColor,
          archived: false,
          userIds: userIds
        };
        var record = store.createRecord('admin-project', newProject);
        record.save().catch(function (reason) {
          self.sendAction('handleError', reason);
          record.rollbackAttributes();
          self.set('selectedProject', null);
          self.resetProjectsList();
        });
        this.initializeSelectedProject(record);
        _analytics.default.sendProjectCreatedEvent(this.get('metrics'));
      }
      this.resetProjectsList();
    },
    resetProjectsList: function resetProjectsList() {
      var projects = this.get('store').peekAll('admin-project');
      this.setProperties({
        projectList: projects.sortBy('name'),
        projects: projects,
        projectSearchText: ''
      });
    }
  });
});