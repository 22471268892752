define('timetracker/routes/login', ['exports', 'timetracker/constants', 'timetracker/utils/network-request'], function (exports, _constants, _networkRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    loginService: Ember.inject.service('loginService'),

    // If the user is already logged in prevent navigation to /login
    // and redirect to redirectionUrl or authenticated.timers route
    beforeModel: function beforeModel() {
      var _this = this;

      var loginService = this.get('loginService');
      return loginService.validate().then(function () {
        _this.replaceWith('authenticated.features');
      }).catch(function (reason) {
        if (reason.status === _constants.default.RESPONSE_CODES.UNAUTHORIZED_ERROR) {
          _this.store.unloadAll();
          return;
        }
      });
    },

    actions: {

      login: function login(loginCredentials) {
        var _this2 = this;

        return this.get('loginService').login(loginCredentials).then(function () {
          return _this2.replaceWith('authenticated.features');
        });
      },

      resendVerifyEmail: function resendVerifyEmail(emailId, teamName) {
        var url = _networkRequest.default.buildURL('sendEmailWithConfirmationLink');
        var data = { teamName: teamName, emailId: emailId };
        var hash = _networkRequest.default.setRequestParameters(url, 'POST', { data: data });
        return _networkRequest.default.makeAPIRequest(hash);
      },

      forgotPassword: function forgotPassword(emailId, teamName) {
        var url = _networkRequest.default.buildURL('requestPasswordReset');
        var data = { teamName: teamName, emailId: emailId };
        var hash = _networkRequest.default.setRequestParameters(url, 'POST', { data: data });
        return _networkRequest.default.makeAPIRequest(hash);
      }
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      var self = this;
      Ember.run.next(this, '_layout');
      Ember.$(window).resize(function () {
        self._layout();
      });
    },

    _layout: function _layout() {
      var bodyHeight = Ember.$('body').innerHeight();
      Ember.$('.login-screen').css('height', bodyHeight);
    }
  });
});