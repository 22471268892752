define('timetracker/components/recent-timers', ['exports', 'moment', 'timetracker/constants', 'timetracker/utils/timeline-util'], function (exports, _moment, _constants, _timelineUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // this variables will determine if setInterval function is running or not
    // setIntervalId will contain the id returned from setInterval function if one is running
    timerIsRunning: false,
    setIntervalId: null,
    timeoutId: null,
    totalMinutesToday: 0, // default value

    i18n: Ember.inject.service(),

    showRecentTimers: function showRecentTimers() {
      var _this = this;

      var totalMinutes = 0;
      var hourMinutes = 60;
      var i18n = this.get('i18n');
      var e = Ember.$('.recent-timers-wrapper');
      var recentTimers = this.get('recentTimers').sortBy('name');
      if (recentTimers.length === 0) {
        var div = document.createElement('div');
        var divRef = Ember.$(div);
        divRef.addClass('empty-recent-timers-message');
        var message = document.createTextNode(i18n.t('messages.emptyRecentTimers'));
        div.appendChild(message);
        e.append(div);
      }

      var _loop = function _loop(i, l) {
        var recentTimer = recentTimers[i];
        var div = document.createElement('div');
        var divRef = Ember.$(div);
        var projectColor = _constants.default.PROJECT_COLORS[recentTimer.colorCode];
        divRef.addClass('recent-timer-container ellipsis');
        divRef.css('color', projectColor);
        var timer = document.createElement('div');
        timer.setAttribute('id', recentTimer.projectId);
        var timerRef = Ember.$(timer);
        timerRef.addClass('recent-timer');
        timerRef.css('backgroundColor', projectColor);
        totalMinutes += parseInt(recentTimer.hours, 10) * hourMinutes + parseInt(recentTimer.minutes, 10);
        var timerValue = document.createTextNode(recentTimer.hours + ':' + recentTimer.minutes);
        timer.appendChild(timerValue);
        var self = _this;
        timer.onclick = function () {
          self.sendAction('timerClicked', recentTimer);
        };
        // check if any of the timers are running, accordingly update the view
        if (recentTimer.timerRunning) {
          var runningTimer = _this.makeCurrentTimerDiv(recentTimer.colorCode, recentTimer.runningTimerHours, recentTimer.runningTimerMinutes);
          div.appendChild(runningTimer);
          timerRef.addClass('active-timer');
          _this.set('timerIsRunning', true);
          _this.setRunningTimer(recentTimers[i], timer, runningTimer);
        }

        div.appendChild(timer);
        var projectName = document.createTextNode(recentTimers[i].name);
        div.appendChild(projectName);
        e.append(div);
      };

      for (var i = 0, l = recentTimers.length; i < l; i++) {
        _loop(i, l);
      }
      this.set('totalMinutesToday', totalMinutes);
      this.renderTotalTime();
    },

    setRunningTimer: function setRunningTimer(timerObject, timer, runningTimer) {

      var initialTimerMS = (60 - parseInt(timerObject.runningTimerSeconds, 10)) * 1000; // eslint-disable-line
      var intervalTimerMS = _constants.default.TIMER_UPDATE_MS;
      var timeoutId = Ember.run.later(this, function () {

        var self = this;
        if (this.get('timerIsRunning') === false) {
          return;
        }
        this.incrementTime(timerObject, timer, runningTimer);
        var setIntervalId = setInterval(function () {

          self.incrementTime(timerObject, timer, runningTimer);
        }, intervalTimerMS);
        this.setProperties({ setIntervalId: setIntervalId, timerIsRunning: true });
      }, initialTimerMS);
      this.set('timeoutId', timeoutId);
    },

    clearRunningTimer: function clearRunningTimer() {
      var _getProperties = this.getProperties('timeoutId', 'setIntervalId'),
          timeoutId = _getProperties.timeoutId,
          setIntervalId = _getProperties.setIntervalId;

      if (timeoutId) {
        Ember.run.cancel(timeoutId);
      }
      if (setIntervalId) {
        clearInterval(setIntervalId);
      }
      this.setProperties({ setIntervalId: null, timeoutId: null, timerIsRunning: false });
    },

    makeCurrentTimerDiv: function makeCurrentTimerDiv(colorCode, hours, minutes) {
      var div = document.createElement('div');
      var divRef = Ember.$(div);
      divRef.addClass('running-timer');
      divRef.css('backgroundColor', _constants.default.PROJECT_COLORS[colorCode]);
      var runningTimerValue = document.createTextNode(hours + ':' + minutes);
      div.appendChild(runningTimerValue);
      return div;
    },

    addMinute: function addMinute(hourString, minuteString) {
      var lastMinuteOfHour = 59;
      var hours = parseInt(hourString, 10);
      var minutes = parseInt(minuteString, 10);
      var newMinute = void 0;
      if (minutes === lastMinuteOfHour) {
        hours += 1;
        newMinute = '00';
      } else {
        minutes += 1;
        // add preceding 0 for minutes < 10
        // eslint-disable-next-line no-magic-numbers
        newMinute = minutes < 10 ? '0' + minutes : '' + minutes;
      }
      var newHour = '' + hours;
      return { newHour: newHour, newMinute: newMinute };
    },
    incrementTime: function incrementTime(timerObject, timerViewObject, runningTimer) {
      // increment time for projectTimer
      var now = (0, _moment.default)();
      // projectTimer hours and minutes to reset at beginning of day
      if (now.hours === 0 && now.minutes === 0) {
        timerObject.hours = '0';
        timerObject.minutes = '00';
      } else {
        var _addMinute = this.addMinute(timerObject.hours, timerObject.minutes),
            _newHour = _addMinute.newHour,
            _newMinute = _addMinute.newMinute;

        timerObject.hours = _newHour;
        timerObject.minutes = _newMinute;
      }
      // update the dom
      var timerValue = timerViewObject.childNodes[0];
      timerValue.nodeValue = timerObject.hours + ':' + timerObject.minutes;

      // increment time for running timer

      var _addMinute2 = this.addMinute(timerObject.runningTimerHours, timerObject.runningTimerMinutes),
          newHour = _addMinute2.newHour,
          newMinute = _addMinute2.newMinute;

      timerObject.runningTimerHours = newHour;
      timerObject.runningTimerMinutes = newMinute;
      var rt = runningTimer.childNodes[0];
      rt.nodeValue = timerObject.runningTimerHours + ':' + timerObject.runningTimerMinutes;
      this.incrementProperty('totalMinutesToday');
      this.renderTotalTime();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.showRecentTimers();
    },
    willUpdate: function willUpdate() {
      this.clearRunningTimer();
      Ember.$('.recent-timers-wrapper').empty();
    },
    willDestroyElement: function willDestroyElement() {
      this.clearRunningTimer();
      Ember.$(this.get('element')).empty();
    },
    renderTotalTime: function renderTotalTime() {
      var totalMinutes = this.get('totalMinutesToday');
      var i18n = this.get('i18n');
      var formattedTime = _timelineUtil.default.getHourMinuteStringFromMinutes(totalMinutes, i18n);
      if (formattedTime) {
        formattedTime += ' ' + i18n.t('strings.time.today');
      } else {
        formattedTime = '';
      }
      var target = Ember.$('.timers-screen-total-time span');
      target.empty();
      target.append(document.createTextNode(formattedTime));
    }
  });
});