define('timetracker/routes/authenticated', ['exports', 'timetracker/constants', 'timetracker/utils/network-request', 'timetracker/utils/logger'], function (exports, _constants, _networkRequest, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    loginService: Ember.inject.service('loginService'),
    i18n: Ember.inject.service(),
    metrics: Ember.inject.service(),
    syncService: Ember.inject.service(),

    setIntervalId: null,

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      var self = this;
      addEventListener(_constants.default.EVENTS.NETWORK_REQUEST_ERROR, function (event) {
        return self._handleRequestErrors(event.reason);
      });
      Ember.run.next(this, '_layout');
      Ember.$(window).resize(function () {
        self._layout();
      });
      var intervalTimerMS = _constants.default.BACKGROUND_SYNC_MS;
      var setIntervalId = setInterval(function () {
        return self.get('syncService').sync();
      }, intervalTimerMS);
      this.setProperties({ setIntervalId: setIntervalId, timerIsRunning: true });
    },

    exit: function exit() {
      clearInterval(this.get('setIntervalId'));
      this.set('setIntervalId', null);
      this.get('syncService').resetChangeTokens();
      Ember.$('.request-error-modal.ui.modal, .conflict-resolution-modal').remove();
    },
    _showErrorModal: function _showErrorModal(closeOutsideClick, shouldLogOut) {
      var self = this;
      Ember.$('.request-error-modal.ui.modal').modal({
        closable: closeOutsideClick,
        onApprove: function onApprove() {
          Ember.$('.request-error-modal.ui.modal').hide();
          if (shouldLogOut) {
            self.send('logout');
          }
        }
      }).modal('show');
    },
    _setModalMessage: function _setModalMessage(message) {
      this.controller.set('errorMessage', message);
    },


    _handleRequestErrors: function _handleRequestErrors(reason) {
      var status = reason.status;
      var emptyErrorCode = '';
      var errorCode = reason.responseJSON ? reason.responseJSON.errorCode : emptyErrorCode;
      var statusUnauthorized = _constants.default.RESPONSE_CODES.UNAUTHORIZED_ERROR;
      var status502 = _constants.default.RESPONSE_CODES.SERVICE_UNAVAILABLE_502;
      var status503 = _constants.default.RESPONSE_CODES.SERVICE_UNAVAILABLE_503;
      var serverError = _constants.default.RESPONSE_CODES.SERVER_ERROR;
      var statusTimeout = _constants.default.RESPONSE_CODES.NETWORK_ERROR;
      var badRequest = _constants.default.RESPONSE_CODES.BAD_REQUEST;
      var userUnauthorized = _constants.default.RESPONSE_401_ERRORS.INVALID_CREDENTIALS;
      var authTokenExpired = _constants.default.RESPONSE_401_ERRORS.AUTH_TOKEN_EXPIRED;
      var disabledUser = _constants.default.RESPONSE_401_ERRORS.USER_DISABLED;
      var passwordChangeRequired = _constants.default.RESPONSE_401_ERRORS.PASSWORD_CHANGE_REQUIRED;
      var i18n = this.get('i18n');
      var errorMessage = void 0;
      var closableModal = void 0;
      var shouldLogOutOnError = void 0;
      if (status === statusUnauthorized) {
        // eslint-disable-line
        shouldLogOutOnError = true;
        closableModal = false;
        switch (errorCode) {
          case userUnauthorized:
          case emptyErrorCode:
            errorMessage = i18n.t('messages.unauthorizedResponse');
            break;
          case disabledUser:
            errorMessage = i18n.t('messages.userIsDisabled');
            break;
          case authTokenExpired:
            errorMessage = i18n.t('messages.authTokenExpired');
            break;
          case passwordChangeRequired:
            {
              return Ember.$('.modal.ui.change-password-modal').modal({
                closable: false
              }).modal('show');
            }
          default:
            _logger.default.error('Unknown error code: ' + errorCode);
        }
        this._setModalMessage(errorMessage);
        this._showErrorModal(closableModal, shouldLogOutOnError);
      } else if (status === status502 || status === status503 || status === serverError || status === statusTimeout || status === badRequest) {
        shouldLogOutOnError = false;
        closableModal = true;
        if (status === statusTimeout) {
          errorMessage = i18n.t('messages.networkError');
        } else if (status === serverError || status === badRequest) {
          errorMessage = i18n.t('messages.genericError');
        } else {
          errorMessage = i18n.t('messages.serviceUnavailable');
        }
        this._setModalMessage(errorMessage);
        this._showErrorModal(closableModal, shouldLogOutOnError);
      }
      return true;
    },

    _layout: function _layout() {
      var minHeight = _constants.default.SETUP.MINIMUM_SUPPORTED_HEIGHT;
      var minWidth = _constants.default.SETUP.MINIMUM_SUPPORTED_WIDTH;
      var windowHeight = Ember.$(window).innerHeight();
      var windowWidth = Ember.$(window).innerWidth();
      var headerHeight = Ember.$('.application-header').height();
      var sidebarWidth = Ember.$('.sidebar').width();
      var availableHeight = Math.max(windowHeight, minHeight);
      var availableWidth = Math.max(windowWidth, minWidth);
      Ember.$('.sidebar').css('height', availableHeight - headerHeight);
      Ember.$('.nested-route-wrapper').css({
        height: availableHeight - headerHeight,
        width: availableWidth - sidebarWidth
      });
    },

    beforeModel: function beforeModel() {
      var loginService = this.get('loginService');
      if (!loginService.isAuthenticated) {
        return this.replaceWith('login');
      }
      var user = loginService.user;
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#userId
      this.set('metrics.context.userId', user.userId);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        user: this.get('loginService').user,
        isAdmin: this.get('loginService').isAdmin
      });
      window.onbeforeunload = function () {
        if (window._apiRequestsPending > 0) {
          return true;
        } else {
          // eslint-disable-line no-else-return
          return null;
        }
      };
    },


    actions: {

      logout: function logout() {
        var _this = this;

        this.get('loginService').logout().then(function () {
          _this.store.unloadAll();
          _this.get('syncService').resetChangeTokens();
          _this.replaceWith('login');
        }).catch(function () {
          return _this.replaceWith('login');
        });
        this.render('application-loading');
      },

      loading: function loading(transition) {
        var spinnerRef = Ember.$('.model-loading-state');
        spinnerRef.removeClass('hidden');
        transition.promise.finally(function () {
          spinnerRef.addClass('hidden');
        });
      },
      updatePassword: function updatePassword() {
        var _this2 = this;

        Ember.$('.error.message').addClass('hidden');

        var _controller$getProper = this.controller.getProperties('oldPassword', 'newPassword', 'confirmedPassword'),
            oldPassword = _controller$getProper.oldPassword,
            newPassword = _controller$getProper.newPassword,
            confirmedPassword = _controller$getProper.confirmedPassword;

        if (!oldPassword || !newPassword || !confirmedPassword) {
          Ember.$('.error.message.required-fields').removeClass('hidden');
        } else if (newPassword !== confirmedPassword) {
          Ember.$('.error.message.new-password-missmatch').removeClass('hidden');
        } else {
          var data = {
            oldPassword: oldPassword,
            newPassword: newPassword
          };
          var url = _networkRequest.default.buildURL('changePassword');
          var hash = _networkRequest.default.setRequestParameters(url, 'POST', { data: data });
          _networkRequest.default.makeAPIRequest(hash).then(function () {
            _this2.controller.setProperties({
              oldPassword: null,
              newPassword: null,
              confirmedPassword: null
            });
            Ember.$('.modal.ui.change-password-modal').modal('hide');
            _this2.get('syncService').sync();
          }).catch(function (reason) {
            if (reason.status === _constants.default.RESPONSE_CODES.UNAUTHORIZED_ERROR) {
              _this2.controller.set('oldPassword', null);
              Ember.$('.error.message.invalid-old-password').removeClass('hidden');
            }
          });
        }
      }
    }

  });
});