define('timetracker/routes/authenticated/features', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render('authenticated.features.featuresSidebar', {
        into: 'authenticated',
        outlet: 'sidebar'
      });
      this.render();
    }
  });
});