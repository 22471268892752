define('timetracker/constants', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    // This application is designed only for screen resolutions >= 1200 x 609
    // This resolution is what an 11 inch MacBook Air can show with the default Safari window
    SETUP: {
      MINIMUM_SUPPORTED_HEIGHT: 609,
      MINIMUM_SUPPORTED_WIDTH: 1200
    },

    REQUEST: {
      TIMEOUT_MS: 60000,
      RETRY_MS: 30000,
      MAX_RETRIES: 3
    },

    RESPONSE_CODES: {
      OK: 200,
      BAD_REQUEST: 400,
      UNAUTHORIZED_ERROR: 401,
      NOT_FOUND: 404,
      SERVER_ERROR: 500,
      SERVICE_UNAVAILABLE_502: 502,
      SERVICE_UNAVAILABLE_503: 503,
      NETWORK_ERROR: 0
    },

    RESPONSE_401_ERRORS: {
      INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
      AUTH_TOKEN_EXPIRED: 'AUTH_TOKEN_EXPIRED',
      USER_DISABLED: 'USER_DISABLED',
      EMAIL_ID_NOT_VERIFIED: 'EMAIL_ID_NOT_VERIFIED',
      PASSWORD_CHANGE_REQUIRED: 'PASSWORD_CHANGE_REQUIRED'
    },

    RESPONSE_400_ERRORS: {
      USER_NOT_AUTHORIZED_TO_MANAGE_PROJECTS: 'USER_NOT_AUTHORIZED_TO_MANAGE_PROJECTS',
      USER_NOT_AUTHORIZED_TO_MANAGE_USERS: 'USER_NOT_AUTHORIZED_TO_MANAGE_USERS',
      PROJECT_ID_ALREADY_EXISTS: 'PROJECT_ID_ALREADY_EXISTS',
      PROJECT_NAME_ALREADY_EXISTS: 'PROJECT_NAME_ALREADY_EXISTS',
      MISSING_USER_IDS: 'MISSING_USER_IDS',
      USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
      CANNOT_CHANGE_EMAIL_ID: 'CANNOT_CHANGE_EMAIL_ID',
      CONFLICT: 'CONFLICT',
      PROJECT_ID_DOES_NOT_EXIST: 'PROJECT_ID_DOES_NOT_EXIST',
      TIMER_ID_ALREADY_EXISTS: 'TIMER_ID_ALREADY_EXISTS',
      PROJECT_ARCHIVED: 'PROJECT_ARCHIVED',
      USER_ID_ALREADY_EXISTS: 'USER_ID_ALREADY_EXISTS',
      USER_WITH_SAME_EMAIL_ID_ALREADY_EXISTS: 'USER_WITH_SAME_EMAIL_ID_ALREADY_EXISTS',
      AT_LEAST_ONE_USER_SHOULD_BE_MANAGING_USERS: 'AT_LEAST_ONE_USER_SHOULD_BE_MANAGING_USERS',
      AT_LEAST_ONE_USER_SHOULD_BE_MANAGING_PROJECTS: 'AT_LEAST_ONE_USER_SHOULD_BE_MANAGING_PROJECTS',
      AT_LEAST_ONE_USER_SHOULD_BE_ABLE_TO_CHANGE_BILLING_DETAILS: 'AT_LEAST_ONE_USER_SHOULD_BE_ABLE_TO_CHANGE_BILLING_DETAILS',
      TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
      EMAIL_ID_NOT_VERIFIED: 'EMAIL_ID_NOT_VERIFIED'
    },

    // interval timer milliseconds for updating timer screen UI
    TIMER_UPDATE_MS: 60000,

    BACKGROUND_SYNC_MS: 30000,

    // project color codes
    PROJECT_COLORS: {
      RED_1: '#FF2D55',
      GREEN_1: '#177D43',
      BLUE_1: '#007AFF',
      ORANGE_1: '#EF6C00',
      PURPLE_1: '#5856D6',
      TEAL_1: '#009688'
    },
    PROJECT_COLORS_TRANSLUCENT: {
      RED_1: 'rgba(255, 45, 85, 0.6)',
      GREEN_1: 'rgba(23, 125, 67, 0.6)',
      BLUE_1: 'rgba(0, 122, 255, 0.6)',
      ORANGE_1: 'rgba(239, 108, 0, 0.6)',
      PURPLE_1: 'rgba(88, 86, 214, 0.6)',
      TEAL_1: 'rgba(0, 150, 136, 0.6)'
    },

    DEFAULT_PROJECT_COLOR: 'RED_1',

    // number of days to consider for recent timers
    RECENT_TIMER_DAYS: 13,

    EVENTS: {
      NETWORK_REQUEST_ERROR: 'networkRequestError',
      SYNC_COMPLETED: 'syncCompleted'
    },

    GRANULARITY: [{
      key: 'day',
      value: 'DAY'
    }, {
      key: 'week',
      value: 'WEEK'
    }, {
      key: 'month',
      value: 'MONTH'
    }],
    WEEKDAYS: [{
      key: 'monday',
      value: 'MONDAY'
    }, {
      key: 'tuesday',
      value: 'TUESDAY'
    }, {
      key: 'wednesday',
      value: 'WEDNESDAY'
    }, {
      key: 'thursday',
      value: 'THURSDAY'
    }, {
      key: 'friday',
      value: 'FRIDAY'
    }, {
      key: 'saturday',
      value: 'SATURDAY'
    }, {
      key: 'sunday',
      value: 'SUNDAY'
    }],
    TIME_FORMATS: [{
      key: 'hourMinutes',
      value: 'HHMM'
    }, {
      key: 'minutes',
      value: 'MINUTES'
    }, {
      key: 'hours',
      value: 'HOURS'
    }],
    REPORT_LEVEL_OPTIONS: [{
      key: 'date',
      value: 'TIME_INTERVAL'
    }, {
      key: 'project',
      value: 'PROJECT'
    }, {
      key: 'user',
      value: 'USER'
    }],
    ROUNDING_TYPES: [{
      key: 'nearestInteger',
      value: 'NEAREST'
    }, {
      key: 'alwaysRoundUp',
      value: 'UP'
    }, {
      key: 'alwaysRoundDown',
      value: 'DOWN'
    }],
    JSON: 'JSON',
    CSV: 'CSV',
    EXCEL: 'EXCEL',

    ROUND_OFF_MINUTES: [0, 15, 30, 60], // eslint-disable-line no-magic-numbers

    REQUEST_ID: 'requestId',
    CLIENT_ID: 'clientId',

    FIRST_WEEK_DAY: (0, _moment.default)().startOf('week').weekday()
  };
});