define('timetracker/serializers/timer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({

    // each model in ember store is specified by 'id' attribute
    // we change this attribute for timer model to be 'timerId'
    primaryKey: 'timerId',

    /**
     Ember data expects the adapter to return JSONAPI formatted response.
     Response from adapter is normalized into JSONAPI format, using normalizeResponse
     @method normalizeFindAllResponse
     @param {DS.Store} store the store class
     @param {object} modelObject model object for timer
     @param {object} payload response data from adapter
     @param {string} id identifier for response in case of single object
     @param {string} responseType the type of store method that invoked the method
     @return {object} JSONAPI formatted object.
     */
    normalizeFindAllResponse: function normalizeFindAllResponse(store, modelObject, payload, id, responseType) {
      var timers = payload.timers;
      return this.normalizeArrayResponse(store, modelObject, timers, id, responseType);
    }
  });
});