define('timetracker/serializers/project', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({

    // each model in ember store is specified by 'id' attribute
    // we change this attribute for project model to be 'projectId'
    primaryKey: 'projectId',

    /**
     Ember data expects the adapter to return JSONAPI formatted response.
     Response from adapter is normalized into JSONAPI format, using normalizeResponse
     @method normalizeFindAllResponse
     @param {DS.Store} store the store class
     @param {object} modelObject model object for project
     @param {object} payload response data from adapter
     @param {string} id identifier for response in case of single object
     @param {string} responseType the type of store method that invoked the method
     @return {object} JSONAPI formatted object.
     */
    normalizeFindAllResponse: function normalizeFindAllResponse(store, modelObject, payload, id, responseType) {
      if (payload.errors) {
        return { data: [] };
      }
      var projects = payload.projects;
      return this.normalizeArrayResponse(store, modelObject, projects, id, responseType);
    }
  });
});