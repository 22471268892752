define('timetracker/services/sync-service', ['exports', 'timetracker/utils/network-request', 'timetracker/utils/sync-modal-handler-util', 'timetracker/constants', 'timetracker/utils/network-error-handler', 'timetracker/utils/timer-util', 'timetracker/utils/logger'], function (exports, _networkRequest, _syncModalHandlerUtil, _constants, _networkErrorHandler, _timerUtil, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    store: Ember.inject.service(),
    i18n: Ember.inject.service(),

    isSyncing: false,
    projectsChangeToken: null,
    timersChangeToken: null,

    requestType: {
      project: 'project',
      timer: 'timer'
    },

    init: function init() {
      this.resetChangeTokens();
    },
    sync: function sync() {
      var _this = this;

      if (this.get('isSyncing')) {
        // sync is already running
        return;
      }
      this.set('isSyncing', true);
      var syncCompleted = new CustomEvent(_constants.default.EVENTS.SYNC_COMPLETED);
      this.syncProjects().then(function () {
        _this.syncTimers().then(function () {
          dispatchEvent(syncCompleted);
          _this.set('isSyncing', false);
        }).catch(function () {
          return _this.set('isSyncing', false);
        });
      }).catch(function () {
        return _this.set('isSyncing', false);
      });
    },
    fetchRecordsFromServer: function fetchRecordsFromServer(type) {
      var url = void 0;
      if (type === this.requestType.project) {
        url = _networkRequest.default.buildURL('getProjects', { changeToken: this.get('projectsChangeToken') });
      } else if (type === this.requestType.timer) {
        url = _networkRequest.default.buildURL('getTimers', { changeToken: this.get('timersChangeToken') });
      }
      var hash = _networkRequest.default.setRequestParameters(url, 'GET');
      return _networkRequest.default.makeAPIRequest(hash).then(function (response) {
        return response;
      }, function (reason) {
        _networkErrorHandler.default.handleErrorResponse(reason, url);
        return reason;
      });
    },
    syncProjects: function syncProjects() {
      var _this2 = this;

      var self = this;
      var projectType = this.requestType.project;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.fetchRecordsFromServer(self.requestType.project).then(function (projectsResponse) {
          _this2.mergeServerChanges(projectsResponse.projects, projectType).then(function () {
            self.set('projectsChangeToken', projectsResponse.projectsChangeToken);
            return resolve();
          });
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    },
    syncTimers: function syncTimers() {
      var _this3 = this;

      var oldTimersChangeToken = this.get('timersChangeToken');
      var self = this;
      var timerType = this.requestType.timer;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.fetchRecordsFromServer(self.requestType.timer).then(function (timersResponse) {
          if (oldTimersChangeToken === timersResponse.timersChangeToken) {
            _this3.updateLocalChanges(timerType).then(function () {
              return resolve();
            }).catch(function (reason) {
              return reject(reason);
            });
          } else {
            _this3.mergeServerChanges(timersResponse.timers, timerType).then(function () {
              self.updateLocalChanges(timerType).then(function () {
                self.set('timersChangeToken', timersResponse.timersChangeToken);
                resolve();
              }).catch(function (reason) {
                return reject(reason);
              });
            });
          }
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    },
    mergeServerChanges: function mergeServerChanges(responseData, type) {
      var store = this.get('store');
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (responseData.length === 0) {
          return resolve();
        }
        return self.mergeRecordSynchronouslyToStore(store, responseData, type, 0).then(function () {
          return resolve();
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    },
    mergeRecordSynchronouslyToStore: function mergeRecordSynchronouslyToStore(store, serverRecords, type, index) {
      var _this4 = this;

      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var serverRecord = serverRecords[index];
        var id = type === _this4.requestType.project ? serverRecord.projectId : serverRecord.timerId;
        var record = store.peekRecord(type, id);
        return _this4.createOrUpdateRecordInStore(record, serverRecord, type).then(function () {
          if (serverRecords.length > index + 1) {
            return self.mergeRecordSynchronouslyToStore(store, serverRecords, type, index + 1).then(function () {
              return resolve();
            });
          }
          return resolve();
        });
      });
    },
    createOrUpdateRecordInStore: function createOrUpdateRecordInStore(storeRecord, serverRecord, type) {
      var _this5 = this;

      var store = this.get('store');
      return new Ember.RSVP.Promise(function (resolve) {
        if (type === _this5.requestType.timer) {
          if (!_timerUtil.default.isAValidTimer(serverRecord)) {
            return resolve();
          }
          if (storeRecord && !_timerUtil.default.isAValidTimer(_timerUtil.default.getJsonFormattedTimer(storeRecord))) {
            store.unloadRecord(storeRecord);
            return resolve();
          }
        }
        var keepServerData = function keepServerData() {
          store.unloadRecord(storeRecord);
          store.push(store.normalize(type, serverRecord));
          return resolve();
        };
        var keepLocalData = function keepLocalData() {
          storeRecord.set('changeToken', serverRecord.changeToken);
          return resolve();
        };
        if (!storeRecord) {
          store.push(store.normalize(type, serverRecord));
          return resolve();
        } else if (!storeRecord.get('hasDirtyAttributes')) {
          // if record is not changed locally
          return keepServerData();
        }
        // Keeping local data by default for project conflicts
        if (type === _this5.requestType.project) {
          return keepLocalData();
        }
        var i18n = _this5.get('i18n');
        return _syncModalHandlerUtil.default.resolveConflictingRecord(store, storeRecord, serverRecord, keepServerData, keepLocalData, i18n).then(function () {
          return resolve();
        });
      });
    },
    updateLocalChanges: function updateLocalChanges(type) {
      var self = this;
      var store = this.get('store');
      var recordsToUpdate = store.peekAll(type).filterBy('hasDirtyAttributes', true);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (recordsToUpdate.length === 0) {
          return resolve();
        }
        return self.updateRecordsSynchronously(recordsToUpdate, 0).then(function () {
          return resolve();
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    },
    updateRecordsSynchronously: function updateRecordsSynchronously(recordsToUpdate, index) {
      var _this6 = this;

      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var record = recordsToUpdate[index];
        _timerUtil.default.testAndArchiveLessThanAMinuteTimers(record);
        return record.save().then(function () {
          if (recordsToUpdate.length > index + 1) {
            return self.updateRecordsSynchronously(recordsToUpdate, index + 1).then(function () {
              return resolve();
            }).catch(function (reason) {
              return reject(reason);
            });
          }
          return resolve();
        }).catch(function (reason) {
          _this6.handleErrorResponse(reason, record);
          return resolve();
        });
      });
    },
    resetChangeTokens: function resetChangeTokens() {
      this.setProperties({
        projectsChangeToken: '0|0',
        timersChangeToken: 0
      });
    },
    handleErrorResponse: function handleErrorResponse(adapterError, record) {
      var statusCode = _networkErrorHandler.default.getErrorStatus(adapterError);
      if (statusCode === _constants.default.RESPONSE_CODES.BAD_REQUEST) {
        var errorCode = _networkErrorHandler.default.getFouHundredErrorCode(adapterError);
        var errorReason = _networkErrorHandler.default.getErrorObject(adapterError);
        switch (errorCode) {
          case _constants.default.RESPONSE_400_ERRORS.CONFLICT:
            {
              // changeToken out of date error is resolved when sync runs the next time
              // TODO track other conflict errors for updateTimer as on API
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.PROJECT_ID_DOES_NOT_EXIST:
            {
              _logger.default.error('Encountered project id does not exist error', record);
              _networkErrorHandler.default.broadcastCustomEvent(errorReason);
              record.rollbackAttributes();
              break;
            }
          case _constants.default.RESPONSE_400_ERRORS.TIMER_ID_ALREADY_EXISTS:
            {
              // TODO update timer id with a new id and retry
              _logger.default.error('Encountered timer id already exist error', record);
              _networkErrorHandler.default.broadcastCustomEvent(errorReason);
              record.rollbackAttributes();
              break;
            }
          default:
            {
              _networkErrorHandler.default.broadcastCustomEvent(errorReason);
              record.rollbackAttributes();
            }
        }
      }
    }
  });
});