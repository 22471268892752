define('timetracker/routes/authenticated/features/timeline', ['exports', 'timetracker/constants', 'timetracker/utils/analytics', 'timetracker/utils/timeline-util'], function (exports, _constants, _analytics, _timelineUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    syncService: Ember.inject.service(),
    metrics: Ember.inject.service(),

    allowSync: true,

    model: function model() {
      var _this = this;

      addEventListener(_constants.default.EVENTS.SYNC_COMPLETED, function () {
        if (_this.get('allowSync')) {
          _this.updateViewData();
        }
      });
      this.get('syncService').sync();
      return Ember.RSVP.hash({
        allProjects: this.store.peekAll('project'),
        allTimers: this.store.peekAll('timer')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var projects = model.allProjects.filterBy('archived', false).sortBy('name').toArray();
      var timers = model.allTimers.filterBy('archived', false).toArray();
      var events = _timelineUtil.default.makeEvents(timers, projects);
      controller.setProperties({ projects: projects, events: events });
    },


    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      var self = this;
      Ember.run.next(this, '_layout');
      Ember.$(window).resize(function () {
        self._layout();
      });
    },

    _layout: function _layout() {
      var wrapperHeight = Ember.$('.nested-route-wrapper').height();
      Ember.$('.full-calendar').fullCalendar('option', 'height', wrapperHeight);
    },

    actions: {
      didTransition: function didTransition() {
        _analytics.default.sendRouteEnteredEvent(this, this.get('metrics'));
      },
      shouldAllowSync: function shouldAllowSync(allowSync) {
        this.set('allowSync', allowSync);
        if (allowSync) {
          this.updateViewData();
        }
      }
    },

    updateViewData: function updateViewData() {
      var modalObject = {
        allProjects: this.store.peekAll('project'),
        allTimers: this.store.peekAll('timer')
      };
      this.setupController(this.controller, modalObject);
    }
  });
});